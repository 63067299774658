export const en: { [key: string]: string } = {
  subscribe_with_click: 'Subscribe with a click',
  subscribe: 'Subscribe with a click',
  minimum_number_characters: 'minimum number of 2 characters',
  request_details: 'Request details',
  form_title: "We'll get in touch with you shortly",
  form_required: 'Fields marked with * are mandatory',
  form_name_placeholder: 'Name',
  form_email_placeholder: 'Email',
  form_phone_placeholder: 'Phone number',
  form_company_placeholder: 'Company',
  form_submit: 'Submit',
  form_1:
    'I give my consent to the processing of personal data according to the',
  form_2: 'Privacy Policy',
  validation_privacy_policy: 'Please accept Privacy Policy',
  validation_phone: 'Phone number is not valid',
  validation_email: 'Invalid email format',
  validation_required: 'Required fields are not filled in',
  submit_error: 'An error occurred while saving the form',
  footer_1: 'Our partners:',
  footer_2: 'Methods',
  footer_3: 'Products',
  footer_4: 'Contacts:',
  footer_5: 'Imprint',
  footer_6: 'Privacy Policy',
  footer_7: 'Market Research',
  about_us_1: '4Service Corporate Social Responsibility',
  about_us_2:
    'From the heart and soul of every member of our team. We do our best to keep a spark of kindness in each of our employees and make the world around us a little better',
  about_us_3: '4Service Corporate Social Responsibility',
  about_us_4:
    'From the heart and soul of every member of our team. We do our best to keep a spark of kindness in each of our employees and make the world around us a little better',
  about_us_5: 'Service Mania business game',
  about_us_6: 'Our outreach initiatives for children and students',
  about_us_7: 'Charity / Volunteering',
  about_us_8:
    'Orphanages, care homes for elderly people and volunteering for the Ukrainian army. The most moving part of our activities',
  about_us_9: 'Animal welfare',
  about_us_10:
    'Anyone can pop into our office with a pet. We lend a hand to shelters, raise funds for food and sometimes also try to find home for homeless animals',
  about_us_11: '4Service Group',
  about_us_12:
    'Is an international research company managing customer experience.',
  about_us_13:
    'We implement complex projects to increase sales and develop business of client-oriented companies.',
  about_us_14:
    'Is an international research holding with headquarters in Vienna, Austria. The company was established in 2001 with an aim to improve the quality of life of its clients and people who interact with business, use its services and consume its products. After all, this is for a reason the name “Forservice” was chosen for a company that represents customer service and good quality.',
  about_us_15:
    'This was the start of the history of 4Service in Kyiv, Ukraine as the leading provider of mystery shopping services. It has continued to operate successfully as a research company across 52 countries with new opportunities in a holistic approach to analyzing and improving the entire customer experience chain',
  about_us_16: 'born in the name of service',
  about_us_17: 'first office located outside of Ukraine',
  about_us_18: 'relocation of headquarters to Vienna',
  about_us_19: 'elite MSPA member, Top-10 agency in Europe',
  about_us_20: 'establishing offices in 9 countries',
  about_us_21: 'first digital projects, Voicer',
  about_us_22: 'partners of DesignThinkers Academy',
  about_us_23: 'first global CХ projects',
  about_us_24: 'Mission',
  about_us_25:
    'Our mission is to improve customer service all over the world! Our inspiration is the qualitative changes we see in the people and companies we work with.',
  about_us_26: '20+ years of Customer Experience',
  about_us_27: '95 countries',
  about_us_28:
    'We collect and share the best industry experience from around the world',
  about_us_29: 'Head office in Vienna',
  about_us_30:
    'countries. We collect and share the best industry experience from around the world',
  about_us_31: 'service quality ratings',
  about_us_32: 'we are in top-10 European CX Agencies',
  about_us_33: 'representative offices / HQ in Austria',
  about_us_34: 'active projects',
  about_us_35: 'Your future workplace',
  about_us_36: 'Our offices',
  about_us_37:
    '4Service ™ takes efforts to create comfortable working space for its employees. Our employees work on-site or remote or hybrid to the best convenience and productivity of each employee. Rooms for relaxation, free psychological support, and a welcoming environment. We are human and pet friendly.',
  about_us_38: 'Team that lights up',
  about_us_39:
    'Our multinational team is committed to listening, leaning into feedback from our customers, and mutual growth. Our clients’ customer journey map with us is as important to our business as launching new products or hitting our financial targets. Our activities are aimed at having a positive impact on the communities around us and to deliver innovation and people-centric solutions for our customers.',
  about_us_40: 'All',
  about_us_41: 'Management',
  about_us_42: 'Team Global',
  about_us_43: 'Product owners',
  about_us_44: 'Our partners',
  about_us_45:
    'We implement complex projects to increase sales and develop the businesses of client-oriented companies',
  about_us_46:
    'is one of the leading ‘design driven’ Professional Training Institutes, represented in 25 countries and train, develop and facilitate creative multidisciplinary teams and communities to drive positive change. Design Thinking enables you to develop and deliver innovative ideas, change and solutions to complicated problems.',
  about_us_47:
    'Their mission is to provide space and time for teams and individuals to be inspired and challenged, to ‘learn-by-doing’ and strengthen their ability for creative problem solving and help organizations build the necessary environment and mindset to kick-start bold innovation.',
  about_us_48:
    'is the representative Trade Association for companies involved in the Mystery Shopping industry. The Association operates on a regional basis worldwide, with chapters located in America, Europe/Africa and Asia/Pacific. Member companies unite as a common body for the purpose of strengthening the Mystery Shopping industry through combined efforts and actions.',
  about_us_49:
    "is a not-for-profit membership organization. Members form a community convinced of the potential of data analytics, research and insights to help improve societies, organizations and everyone's life.",
  about_us_50:
    'ESOMAR is headquartered in Amsterdam, the Netherlands. ESOMAR is present in over 130 countries through its members since 1947.',
  about_us_51:
    '(UCCA) is a professional community of private experts and companies united at the aim of development and interactions on the area of contact center and customer service. The Philosophy of the UCCA is raising the culture of the contact center industry, based on modern technologies and high standards.',
  about_us_52: 'The Ukrainian Contact Center Association was founded on 2010',
  about_us_53:
    'studies the best world experience in the field of education to adapt and use it on the Ukrainian market. That is why Academy DTEK cooperates with the leading international business schools: INSEAD, IE Business School and Stanford University.',
  about_us_54:
    'Academy DTEK is focused on the development of a client-oriented and innovative culture in D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, DX School digital program, Effective Leader: Pre-MBA, Change Energy.',
  about_us_55: 'Sergey Pashkovsky',
  about_us_56: 'Viktoria Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Max Papka',
  about_us_59: 'International Sales Manager',
  bht_1: 'Brand Health',
  bht_2: 'Tracking',
  bht_3: 'Brands are incredibly powerful',
  bht_4: 'Find out how well your brand is performing overall',
  bht_5: 'About',
  bht_6: 'What is Brand Health Tracking?',
  bht_7:
    'This is a type of market research that is conducted with a certain regularity (every two weeks, every month etc.) in order to obtain information about the',
  bht_8:
    'status of a brand on the market based on indicators such as prominence, demand, image, the characteristics of its consumers, brand health, etc.',
  bht_9: 'Buy the brand most often',
  bht_10: 'Loyal',
  bht_11: 'Bought the brand for 3 month',
  bht_12: 'Repertory / regular customers',
  bht_13: 'Bought the brand for 6 month',
  bht_14: 'Customers in the short term',
  bht_15: 'Bought the brand for a year',
  bht_16: 'Customers in the long term',
  bht_17: 'Are aware of the brand? but have never used/bought it',
  bht_18: 'Aware but not customers',
  bht_19: 'Unaware: have never heard about the brand',
  bht_20: 'Unaware',
  bht_21: 'Brand buyers profile',
  bht_22:
    'Evaluating the consistency of efforts put in in the long-term to build a brand',
  bht_23: 'Measuring the level of customer knowledge about the brand',
  bht_24:
    'Showing the effectiveness of the advertising and information campaigns',
  bht_25:
    'Determining what impact a period of high advertising has on the sales/marketing activities of the competitors',
  bht_26:
    'Providing a constant control to the business owner in periods of continuous market changes',
  bht_27: 'What tasks can be solved by BHT:',
  bht_28: 'Request a research',
  bht_29: 'Task to solve',
  bht_30: 'For example,',
  bht_31:
    'if a company needs only one session of BHT per quarter during the measurement of market shares, a survey of respondents should be conducted more often when measuring customer brand knowledge from advertising.',
  bht_32: 'CATI',
  bht_33: 'Face-to-Face',
  bht_53: 'Interviews',
  bht_34: 'СAWI',
  bht_35: 'Task to solve',
  bht_36: 'By collecting information',
  bht_37:
    'through various methods, brand tracking is usually divided into three stages depending on the target audience and how difficult they are to reach:',
  bht_38: 'We use both traditional and contemporary methods to collect data:',
  bht_39:
    'We work with using two models: the first one with a simple set of indicators and the second one targets more complex industries such as food and service industries. A more complex model involves an in-depth study that includes up to 10 estimated indices.',
  bht_40: 'We offer',
  bht_41: 'a control of initial data',
  bht_42: 'We cover',
  bht_43: 'the whole of the country',
  bht_44: 'We provide a solution',
  bht_45:
    'for non-dynamic markets/markets with a limited number of players (Brand Point Analyze)',
  bht_46: 'Why us?',
  bht_47: 'Why choose us?',
  bht_50: 'Poor result',
  bht_51: 'Good result',
  bht_52: 'Average result',
  cases_1:
    'Challenge: How to improve customer electric vehicle buying experience in Hyundai showrooms?',
  cases_2: 'Customer Journey mapping',
  cases_3: 'Ideation',
  cases_4: 'Qualitative research',
  cases_5:
    'Challenge: What should be changed in the stores (both in service process, in the interior, in brand perception) in order to get customers interest into brand Oppo?',
  cases_6: 'Online CJM',
  cases_7: 'UI testing',
  cases_8: 'Interviews',
  cases_9:
    'Challenge: What should be changed in the stores in order to get customers interest into brand Oppo?',
  cases_10: 'Qualitative part',
  cases_11: 'Ideation',
  cases_12: 'Quantitative part',
  cases_13: 'Customer Journey Map',
  cases_14: 'Cases',
  cases_15: 'All',
  cases_16: 'Let‘s make your great case together',
  csi_1: 'Customer',
  csi_2: 'satisfaction index',
  csi_3: 'Determine the reasons for consumers’',
  csi_4: 'satisfaction and dissatisfaction',
  csi_5: 'Research into customer satisfaction',
  csi_6:
    'is the determination of the factors, causes and motives that influence a customer’s decision on purchasing as well as the behavior of the target audience.',
  csi_7: 'A customer satisfaction survey',
  csi_8:
    'shows how satisfied or dissatisfied customer is with a particular aspect of service or the product in general.',
  csi_9: 'The satisfaction survey',
  csi_10:
    'is conducted among company’s existing customers and users of certain products/services.',
  csi_11: 'What is CSI?',
  csi_12:
    'Customer satisfaction measures the extent to which customer’s expectations have been satisfied regarding the purchase of a product or service provided by your company.',
  csi_13: 'Happy man',
  csi_14: 'About',
  csi_15: 'Identify any hidden factors',
  csi_16: 'that may affect the customer satisfaction',
  csi_17: 'Determine the reasons',
  csi_18: 'behind any rise/drop in demand for goods and services',
  csi_19: 'Optimize the company’s',
  csi_20: 'business processes',
  csi_21: 'Adapting them',
  csi_22: 'for the needs of the modern consumer',
  csi_23: 'Form an effective advertising message',
  csi_24: 'and position a product under the client’s request',
  csi_25: 'Tasks to solve',
  csi_26: 'What tasks customer satisfaction surveys can solve',
  csi_27: 'Man with phone',
  csi_28: 'We construct a mathematical model',
  csi_29:
    'of factors which may influence customer satisfaction/customer loyalty (regression analysis)',
  csi_30: 'Optimize the',
  csi_31: 'company’s business processes',
  csi_32: 'We build a calculated customer satisfaction index',
  csi_33: '(CSI) and compare it with declarative one',
  csi_34: 'We conduct a research',
  csi_35: 'for both B2B and B2C segments.',
  csi_36: 'Our questionnaires are developed by a team',
  csi_37: 'of analysts especially for the individual projects of our clients',
  csi_38: 'Why us',
  csi_39: 'Why choose us?',
  csi_40: 'Methods of collecting information:',
  main_1:
    'of our customers improve their financial performance by learning from the experience of their clients',
  main_2: 'СX research & consulting services: a 360° view of your business',
  main_3: 'Discover our range of solutions and tools to support your business',
  main_4: 'Products',
  main_5:
    'Qualitative research is a collecting and analyzing non-numerical data to understand concepts, opinions, or experiences',
  main_6:
    'Quantitative research methods is an objective measurement and numerical analysis of data collected through polls, questionnaires, and surveys',
  main_7:
    'Mystery shopping is a market research technique for measuring, and monitoring the interactions between a customer and a company or organisation during a pre-defined scenario',
  main_8:
    'Feedback Management System. Voice of your customer in one system Customer Feedback Management System',
  main_9:
    'An AI-powered content intelligence platform providing insights from the audience',
  main_10:
    'Performance Management Software. Gamified application for increasing sales',
  main_11:
    "Price monitoring is the process of collection, processing, and analysis of the competitors' prices",
  main_12:
    'is the design of customer-oriented services and products. The process involves a balanced movement from the needs and expectations of customers to the way they operate',
  main_13:
    'Our analytical team applies the most up-to-date and fundamental approaches that we’ve proven to be successful with many clients',
  main_14: 'Methodologies',
  main_15: 'Customer journey map',
  main_16:
    'Customer Journey Mapping (CJM) is about defining a target audience, understanding their needs and environment, and then creating a plan to deliver an engaging experience',
  main_17: 'CJM',
  main_18: 'Net Promotion Score',
  main_19:
    'Net Promoter Score (NPS) is the gold standard of customer experience metrics, based on one question: How likely would you recommend Organization/Product/Service?',
  main_20: 'NPS',
  main_21: 'Brand Health Tracking',
  main_22:
    'Brand Health Tracking (BHT) research can measure how your brand is performing on awareness and usage, brand positioning, and brand performance',
  main_23: 'BHT',
  main_24: 'Human Resources Research',
  main_25:
    'Human Resource Research is used to evaluate HR practices and performance and offers detail analysis on the current development and management',
  main_26: 'HR research',
  main_27: 'Customer satisfaction index',
  main_28:
    "The Customer Satisfaction Index (CSI) is a score that indicates how satisfied your customers are with the company's products / services.",
  main_29: 'CSI',
  main_30: 'UX testing',
  main_31:
    'Usability (UX) testing is how real people interact with a website, app, or other product and observing their behavior and reactions to it',
  main_32: 'Why us',
  main_33:
    'Our mission is to help businesses provide highly effective customer experience',
  main_34: 'Focusing on quality',
  main_35: 'We are quality-controlled',
  main_36:
    'when it comes to research data. We make all data sets available to clients',
  main_37: 'Cost-effective',
  main_38: 'Our experience, resources and streamlined',
  main_39: 'business processes allow us to save you money and time',
  main_40: 'Smart solutions',
  main_41: 'We use a variety of techniques',
  main_42: 'and toolkits to solve your particular problems',
  main_43: 'Focusing on quality',
  main_44: 'We quality-controlled',
  main_45:
    'when it comes to research data. We make all data sets available to clients',
  main_46: 'Cost-effective',
  main_47: 'Our experience, resources and streamlined',
  main_48: 'business processes allow us to save you money and time',
  main_49: 'Smart solutions',
  main_50: 'We use a variety of techniques',
  main_51: 'and toolkits to solve your particular problems',
  main_52: 'Cash & Carry',
  main_53: 'Electronics',
  main_54: 'Luxury/Premium',
  main_55: 'Dining & Hospitality',
  main_56: 'Retail',
  main_57: 'Automotive',
  main_58: 'Fashion',
  main_59: 'Banks',
  main_60: 'Travel & Leisure',
  main_61: 'Watches & Jewelry',
  main_62: 'Household & Appliances',
  main_63: 'Blog',
  main_64: 'Go to the blog to read more interesting posts',
  main_65: 'Retail audit',
  main_66:
    'Collect detailed data on your store or brand, analyze growth barriers, and create effective strategies to overcome them',
  cjm_1: 'Customer',
  cjm_2: 'Journey map',
  cjm_3: 'Enter the world of customers and share in their experience',
  cjm_4: 'What is Customer Journey Map?',
  cjm_5:
    'CJM provides a visualized history of consumer interaction with a product, service, company, or brand through various channels and during a certain period of time.',
  cjm_6:
    'This allows companies to objectively analyze the experience of customer interaction, fix and eliminate any barriers that arise and to offer recommendations for improving the product.',
  cjm_7: 'Determination of TA, segmentation',
  cjm_8: 'Identification of the stages of customer interaction',
  cjm_9: 'Identification of primary customer interaction channels',
  cjm_10: 'Research of customer experience',
  cjm_11: 'Analysis of barriers and hypothesis on optimization',
  cjm_12: 'Execution and testing of hypotheses',
  cjm_13: 'Designing a Customer Journey Map consists of the following steps:',
  cjm_14: 'The Customer Journey Map will demonstrate:',
  cjm_15:
    'CJM provides a visualized history of consumer interaction with a product, service, company, or brand through various channels and during a certain period of time.',
  cjm_16: 'We ensure teamwork',
  cjm_17: 'between analysts and designers',
  cjm_18: 'We offer an individual',
  cjm_19: 'approach to each client',
  cjm_20: 'We can increase overall',
  cjm_21:
    'customer satisfaction with the service/product and ultimately increase their loyalty to the company',
  cjm_22: 'We guarantee the',
  cjm_23:
    'optimization of the consumer’s path and take into account the company’s business processes',
  cjm_24: 'We offer our customers',
  cjm_25:
    'the opportunity to use combined approaches: CATI, CAWI, focus groups, in-depth interviews, expert interviews, ethnography, etc.',
  cjm_26: 'Why choose us?',
  methods_hr_1: 'Human Resources Research',
  methods_hr_2:
    'Get objective insights about your team and find how to improve HR performance',
  methods_hr_3: 'Some reasons why you may need HR research',
  methods_hr_4: 'of the staff interviewed',
  methods_hr_5: 'hr projects implemented',
  methods_hr_6: 'staff training sessions have been held',
  methods_hr_7:
    'Leading European holding for customer experience and employee research',
  methods_hr_8: '4service HR researches in numbers',
  methods_hr_9:
    "To identify problems and weaknesses in each stage of the life of your company's employees: search/recruitment/onboarding/career development/recertifications",
  methods_hr_10: 'To know what occasions deserve raises and bonuses',
  methods_hr_11:
    'You need to predict or provide solutions of employees dissatisfaction',
  methods_hr_12:
    'Through HR research new and current methods of evaluation of HR policies, programmes and practices are possible',
  methods_hr_13: 'To improve the mental health of company employees',
  methods_hr_14:
    'If you want to improve productivity through a goal-oriented approach',
  methods_hr_15:
    'If you have an international and/or remote team and you need to manage it',
  methods_hr_16:
    'To understand how attractive your employer brand is to potential employees',
  methods_hr_17: 'Employee Satisfaction',
  methods_hr_18: 'HR Branding Research',
  methods_hr_19: 'Cultural Diversity',
  methods_hr_20: 'Compensation and Salary Plansies',
  methods_hr_21: 'Team Engagement and Loyality',
  methods_hr_22: 'Protected Class Employees',
  methods_hr_23: 'Areas of research',
  methods_hr_24: 'Solution 1',
  methods_hr_25: 'eNPS, Employee Satisfaction, Employee Loyalty',
  methods_hr_26: 'What we measure:',
  methods_hr_27: 'Loyalty:',
  methods_hr_28:
    'employee loyalty, predicting future behavior, determining the weight of factors that affect loyalty',
  methods_hr_29: 'Involvement:',
  methods_hr_30:
    'involvement of employees in work, interest in results and achievements',
  methods_hr_31: 'Engagement:',
  methods_hr_32: 'identification of employee values and company values',
  methods_hr_33: 'Trust 360:',
  methods_hr_34:
    'trust in management, co-workers, subordinates, trust in employer strategies and policies',
  methods_hr_35: 'Satisfaction:',
  methods_hr_36:
    'defining the unique factors for each company, evaluation of satisfaction on each key factor affecting loyalty',
  methods_hr_37: 'Solution 2',
  methods_hr_38: 'Employer branding',
  methods_hr_39: 'Internal employer',
  methods_hr_40:
    'brand analysis (staff, stakeholder, internal recruiters and HR specialists survey)',
  methods_hr_41: 'External employer analysis',
  methods_hr_42:
    '(competitor EVP analysis, external recruiter survey, job seeker survey)',
  methods_hr_43: 'Brand awareness and reputation',
  methods_hr_44: 'analysis',
  methods_hr_45: 'Employer presence',
  methods_hr_47: 'Employer reference',
  methods_hr_48: 'collection',
  methods_hr_49: 'Competitive benchmarking',
  methods_hr_50: 'on key factors such as',
  methods_hr_51: 'Salary,',
  methods_hr_52: 'working environment, atmosphere, nature of work',
  methods_hr_53: 'Analysis of needs and expectations',
  methods_hr_54: 'of job seekers',
  methods_hr_55: 'In-depth interviews,',
  methods_hr_56: 'desk research and quantitative research are used',
  methods_hr_57: 'Solution 3',
  methods_hr_58: 'Employee journey map/mystery employee',
  methods_hr_59: 'constructing the jobseeker journey identifying',
  methods_hr_60:
    'the main stages of the search, channels of communication, selection criteria and assessment of jobseekers',
  methods_hr_61: 'monitoring all stages',
  methods_hr_62:
    'and channels of communication with mystery job seekers (just making an appointment for an interview, having an interview, an internship in the company)',
  methods_hr_63: 'Identification',
  methods_hr_64: 'of weaknesses in the recruitment and onboarding processes',
  methods_hr_65: 'Recommendations for improvement,',
  methods_hr_66: 'benchmarking with industry leaders',
  mystery_shopping_1: 'Mystery',
  mystery_shopping_2: 'Shopping',
  mystery_shopping_3: 'Evaluate your service with real customers',
  mystery_shopping_4:
    'Check standards assess your staff become closer to your customer',
  mystery_shopping_5:
    'We are Elite member of professional leading Mystery Shopping Providers association MSPA Europe',
  mystery_shopping_6: 'of experience',
  mystery_shopping_7: 'visits monthly',
  mystery_shopping_8: 'mystery shoppers worldwide',
  mystery_shopping_9: 'Active projects',
  mystery_shopping_10: 'offices, HQ in Austira',
  mystery_shopping_11: 'Europian Mystery Shopping Providers',
  mystery_shopping_12: 'service quality ratings',
  mystery_shopping_13: 'Questionnaire',
  mystery_shopping_14: 'Software',
  mystery_shopping_15: "Shopper's profile",
  mystery_shopping_16: 'Validation and objection',
  mystery_shopping_17: 'Analytics',
  mystery_shopping_18:
    'With these 5 steps, our company 4Service guarantees the quality of the mystery shopper service',
  mystery_shopping_19: 'Programme',
  mystery_shopping_20:
    'The 5 elements of the Mystery Shopping programme by 4Service',
  mystery_shopping_21: 'Questionnaire:',
  mystery_shopping_22: 'the core of your programme',
  mystery_shopping_23:
    'This part of the programme is viewable by all stakeholders',
  mystery_shopping_24: 'For top managers.',
  mystery_shopping_25:
    'Show all the important steps and details of the process',
  mystery_shopping_26: 'For employees.',
  mystery_shopping_27: 'Reflect what employers expect from them',
  mystery_shopping_28: 'For mystery shoppers.',
  mystery_shopping_29: 'Clear and structured to avoid confusion and bias',
  mystery_shopping_30: 'Shopmetrics Software:',
  mystery_shopping_31: 'user-friendly and innovative',
  mystery_shopping_32: 'Benefits:',
  mystery_shopping_33: 'Integrates',
  mystery_shopping_34: 'with your business processes and software',
  mystery_shopping_35: 'GDPR-Driven',
  mystery_shopping_36: 'Privacy Management Toolkit',
  mystery_shopping_37: 'Detailed',
  mystery_shopping_38: 'Legal Compliance Package',
  mystery_shopping_39: 'Report',
  mystery_shopping_40: 'builder',
  mystery_shopping_41: 'Management',
  mystery_shopping_42: 'of access levels',
  mystery_shopping_43: 'Most questionnaires',
  mystery_shopping_44: 'are completed within an hour',
  mystery_shopping_45: 'Shoppers profile:',
  mystery_shopping_46: '200 000+ mystery shoppers worldwide',
  mystery_shopping_47:
    'Main criteria: maximum proximity to your target audience, with previous experience with your company or competitors',
  mystery_shopping_48: 'Shoppers’ training:',
  mystery_shopping_49: 'Introduction training test',
  mystery_shopping_50: 'Skype/Phone call',
  mystery_shopping_51: 'Evaluation test (optional)',
  mystery_shopping_52: 'Validation and objections',
  mystery_shopping_53: 'Validation',
  mystery_shopping_54: 'System',
  mystery_shopping_55: '100% reports',
  mystery_shopping_56: 'are reviewed by the validation team',
  mystery_shopping_57: 'Additional calls',
  mystery_shopping_58: 'for clarification',
  mystery_shopping_59: 'Audio records',
  mystery_shopping_60: 'after the visit to note the most important issues',
  mystery_shopping_61: 'Employee fraud',
  mystery_shopping_62: 'prevention system',
  mystery_shopping_63: 'Objection',
  mystery_shopping_64: 'System',
  mystery_shopping_65: "Any shopper's assessment",
  mystery_shopping_66:
    'can be objected by the employee on the website directly',
  mystery_shopping_67: 'Each objection',
  mystery_shopping_68:
    'is considered and responded to by the local validation team',
  mystery_shopping_69: 'You can see the statistic',
  mystery_shopping_70: 'of the objections',
  mystery_shopping_71: 'Analytics.',
  mystery_shopping_72: 'The report are presented in person and contains:',
  mystery_shopping_73: "Company's",
  mystery_shopping_74: 'pain points and concerns',
  mystery_shopping_75: 'Dynamics',
  mystery_shopping_76: 'by divisions and sections',
  mystery_shopping_77: 'Correlation',
  mystery_shopping_78: 'between meeting standards and customer satisfaction',
  mystery_shopping_79: 'Benchmarking',
  mystery_shopping_80: 'against competitors',
  mystery_shopping_81: 'Practical',
  mystery_shopping_82: 'recommendations on service improvements',
  mystery_shopping_83: 'What makes us different?',
  mystery_shopping_84: 'We carry out ideation sessions',
  mystery_shopping_85:
    'We not only recommend what to do, but we together with you look for practical ways to solve service problems.',
  mystery_shopping_86: 'Ideation sessions are a powerful tool that helps us:',
  mystery_shopping_87:
    'To develop directions for product or services improvement',
  mystery_shopping_88:
    'To explore new revenue stream opportunities and business strategies',
  mystery_shopping_89:
    'To find solutions to intricate customer-centric challenges',
  mystery_shopping_90: 'To transform customers’ pain points into delights',
  mystery_shopping_91: 'We conduct video interviews',
  mystery_shopping_92:
    "We don't just collect questionnaires - we also get live comments from shoppers",
  mystery_shopping_93: 'We do this in order to:',
  mystery_shopping_94:
    'Our facilitators will be conduct in-depth interviews and give effective feedback',
  mystery_shopping_95: "See the experience from the customer's perspective",
  mystery_shopping_96: 'Enhance your content marketing strategy',
  mystery_shopping_97: 'Predict customer behavior',
  mystery_shopping_98: 'We design Customer Journey Map',
  mystery_shopping_99:
    'Customer Journey Mapping helps us to step into customers shoes to understand their needs and challenges.',
  mystery_shopping_100: 'Сreating a customer journey is the best way to:',
  mystery_shopping_101: "See the experience from the customer's perspective",
  mystery_shopping_102: 'Enhance your content marketing strategy',
  mystery_shopping_103: 'Predict customer behavior',
  mystery_shopping_104: 'Identify gaps in service or communications',
  mystery_shopping_105: 'We provide different types of Mystery Shopping',
  mystery_shopping_106: 'Types of Mystery Shopping',
  mystery_shopping_107: 'Mystery Caller',
  mystery_shopping_108:
    'Calls your managers and/or call centre to determine the quality of service provided over the phone',
  mystery_shopping_109: 'Luxury mystery shopping',
  mystery_shopping_110:
    'Checking service in luxury locations by a special category of mystery shoppers',
  mystery_shopping_111: 'Competitive visits',
  mystery_shopping_112:
    'The easiest way to understand how your competitor operates',
  mystery_shopping_113: 'Mystery Employee',
  mystery_shopping_114:
    "Will help you to understand your employee's whole journey (hiring, adaptation, office processes, etc.)",
  mystery_shopping_115: 'Motivational visits',
  mystery_shopping_116:
    'A useful tool for stimulating the offer of additional services and/or products, sales of specific products',
  mystery_shopping_117: 'Mystery Shopper Online',
  mystery_shopping_118:
    'Simulates and measures customer experience in your online shop, website or app',
  mystery_shopping_119: 'Sales & promotions check',
  mystery_shopping_120:
    'Aids in identifying how promotions are displayed in all locations and how effectively do they work',
  mystery_shopping_121: 'Purchase and return visits',
  mystery_shopping_122:
    'Helps to reflect the entire process from purchase to return in order to improve the customer experience',
  mystery_shopping_123: '> 20 years',
  nps_1: 'Net Promotion',
  nps_2: 'Score',
  nps_3: 'Measure, track and improve customer loyalty',
  nps_4: 'They respond with a score of 0 to 6.',
  nps_5:
    'They may have had a poor experience and who are unlikely to buy from you again, and may even discourage others from buying from you.',
  nps_6: 'These are respondents who score between 7 and 8.',
  nps_7:
    'They are satisfied with your service but not happy enough to be considered promoters.',
  nps_8: 'These are the respondents who rate your business as a 9 or a 10.',
  nps_9:
    'This means that they would recommend you to a friend or colleague, and are therefore advocating on your behalf.',
  nps_10: 'What is NPS?',
  nps_11: 'It measures customer perception based on one simple question:',
  nps_12:
    'How likely is it that you would recommend [Organization/Product /Service] to a friend or colleague?',
  nps_13: 'Does my company need it?',
  nps_14: 'NPS is your sustainable growth',
  nps_15: 'Understand the Dynamics of Customer Loyalty',
  nps_16: 'Motivate your staff',
  nps_17: 'Understand the relationship to different products',
  nps_18: 'Compare how customers treat you and your competitors',
  nps_19: 'What can I do with NPS?',
  nps_20: 'What kind of business is NPS relevant for?',
  nps_21: 'Information gathering methods:',
  nps_22: 'Our recommendations when working with NPS',
  nps_23:
    'This means that we develop guidelines to help turn Detractors into promoters',
  nps_24: 'We work in a "closed loop" method',
  nps_25:
    'NPS is a tracking research in which we track changes in customer loyalty',
  nps_26: 'We monitor the dynamics',
  nps_27:
    'We offer to always evaluate the competitors in your niche for a more in-depth analysis',
  nps_28: 'Competitors evaluation',
  price_monitoring_1: 'Price Monitoring',
  price_monitoring_2: 'Retail Monitoring',
  price_monitoring_3:
    'Increase sales potential and optimise your pricing policy through competitor price monitoring',
  price_monitoring_4:
    '4Service has been providing price monitoring services worldwide for more than 20 years',
  price_monitoring_5:
    'Prices are monitored within a short time by our team of qualified in-house auditors, allowing us to respond quickly to changes in competitors prices',
  price_monitoring_6:
    'Challenges our company can help you to solve with price monitoring',
  price_monitoring_7: "Evaluating competitors' pricing",
  price_monitoring_8: 'and product policies',
  price_monitoring_9: 'Monitoring promotions',
  price_monitoring_10: 'and special offers',
  price_monitoring_11: 'Monitoring market conditions',
  price_monitoring_12: 'and market environment',
  price_monitoring_13: 'Checking and controlling sales outlets',
  price_monitoring_14: 'for special equipment and merchandise',
  price_monitoring_15: 'Managing product',
  price_monitoring_16: 'flow within the trade',
  price_monitoring_17: 'Optimizing conditions',
  price_monitoring_18: 'for better collaboration with suppliers, dealers',
  price_monitoring_19: 'Measuring shelf space,',
  price_monitoring_20: 'assessing positioning of goods, stock availability',
  price_monitoring_21: 'Key indicators and monitoring criteria',
  price_monitoring_22: 'All data is compiled in one interface including:',
  price_monitoring_23: 'name',
  price_monitoring_24: 'of the product and brand category',
  price_monitoring_25: 'availability',
  price_monitoring_26: 'of promotional materials',
  price_monitoring_27: 'price',
  price_monitoring_28: 'and availability of any promotional offers',
  price_monitoring_29: 'shelf',
  price_monitoring_30: 'size',
  price_monitoring_31: 'product',
  price_monitoring_32: 'display',
  price_monitoring_33: 'SKU',
  price_monitoring_34: 'facing',
  price_monitoring_35: 'type',
  price_monitoring_36: 'of packaging',
  price_monitoring_37: 'any other',
  price_monitoring_38: 'general information',
  price_monitoring_39: 'Pre-project analysis:',
  price_monitoring_40: 'We study',
  price_monitoring_41:
    "your company's pricing and product policy, business and industry practices",
  price_monitoring_42: 'We provide',
  price_monitoring_43:
    'prompt training, testing and certification for auditors',
  price_monitoring_44: 'We customise',
  price_monitoring_45: 'and set up the software',
  price_monitoring_46: 'Field work:',
  price_monitoring_47: 'Visits to retail',
  price_monitoring_48: 'outlets',
  price_monitoring_49: 'Reporting:',
  price_monitoring_50: 'Our analytical team',
  price_monitoring_51:
    "prepares online reports in the company's personal accounts",
  price_monitoring_52: 'We provide',
  price_monitoring_53:
    'a full photo report, PPT report, Excel report in terms of type of locality, chain name, outlet address',
  price_monitoring_54: 'How it',
  price_monitoring_55: 'works',
  price_monitoring_56: 'the size of individual discounts',
  price_monitoring_57: 'cost calculation',
  price_monitoring_58: 'size of individual bonuses',
  price_monitoring_59: 'loyalty programs',
  price_monitoring_60: 'etc.',
  price_monitoring_61: 'We use specialized software:',
  price_monitoring_62:
    'PRADATA, Shopmetrics, and offer comprehensive technical support',
  price_monitoring_63: 'Rapid deployment',
  price_monitoring_64: 'and scaling (from day 1)',
  price_monitoring_65: 'All data confirmed',
  price_monitoring_66: 'by photos and GPS tagging',
  price_monitoring_67: 'We have the necessary number',
  price_monitoring_68: 'of field agents in any country',
  price_monitoring_69: 'We can work with large',
  price_monitoring_70: 'amounts of data',
  price_monitoring_71: 'Monitoring of individual prices',
  price_monitoring_72:
    'If you have complex products - we can help you define your pricing strategy',
  price_monitoring_73:
    "We can monitor individual competitor's prices, for example:",
  price_monitoring_74: 'Low',
  price_monitoring_75: 'cost',
  price_monitoring_76: 'High',
  price_monitoring_77: 'efficiency',
  price_monitoring_78: 'Excel report',
  price_monitoring_79: 'PPT report',
  price_monitoring_80: 'BI report',
  price_monitoring_81:
    'Competitor pricing data helps you make the best pricing decisions',
  price_monitoring_82:
    'We provide a full photo report, PPT report, Excel report in terms of type of locality',
  qualitative_research_1: 'Qualitative',
  qualitative_research_2: 'researches',
  qualitative_research_3:
    'Collect, analyse and interpret data through the observation of actions and words of your customers',
  qualitative_research_4: 'Values',
  qualitative_research_5: 'Behaviour',
  qualitative_research_6: 'Background',
  qualitative_research_7: 'Expectations',
  qualitative_research_8: 'Enviroment',
  qualitative_research_9: 'Fears/Barriers',
  qualitative_research_10: "What is the purpose of the customer's choice?",
  qualitative_research_11: 'Man with phone',
  qualitative_research_12: 'brand',
  qualitative_research_13: 'category',
  qualitative_research_14: 'advertising',
  qualitative_research_15: 'product',
  qualitative_research_16:
    'The uniqueness of qualitative methods is that they allow us to understand the causes and motivation of consumer behavior',
  qualitative_research_17:
    "Аnalysis of the consumer's attitude to a particular phenomenon",
  qualitative_research_18: 'Individual perspectives and experiences',
  qualitative_research_19: 'Topics that cannot be investigated through surveys',
  qualitative_research_20: 'Sensitive topics',
  qualitative_research_21: 'Hard to reach audience',
  qualitative_research_22: 'Complex geography',
  qualitative_research_23: 'In-depth interviews useful for:',
  qualitative_research_24: 'In-depth interviews',
  qualitative_research_25:
    'An in-depth interview is a qualitative research technique that involves conducting individual interviews with respondents to explore their perspectives on a particular idea, program, or situation. An in-depth interview explores individual experiences / perceptions / practices in rich detail.',
  qualitative_research_26: 'Aims:',
  qualitative_research_27: 'Characterizing social and cultural norms',
  qualitative_research_28: 'Hard to reach audience',
  qualitative_research_29:
    'Sharing and comparing (Morgan) investigated through surveys',
  qualitative_research_30: 'Topics that cannot be investigated through surveys',
  qualitative_research_31: 'In-depth focus groups useful for:',
  qualitative_research_32: 'Focus groups',
  qualitative_research_33:
    'Deep exploration of individual meetings, emerging discussions, discussions between manifestations under the influence of the group moderator.',
  qualitative_research_34:
    'Generating insights into shared experiences and social norms through group discussion, narrative data in a focused discussion.',
  qualitative_research_36: 'Benefits:',
  qualitative_research_37:
    'Studying the lifestyle and behavior of the consumer in various real conditions (at home, at the point of sale, at work, etc.)',
  qualitative_research_38:
    'Search for insights for the development of new products, packaging, service improvement, etc.',
  qualitative_research_39: 'In-depth ethnography research useful for:',
  qualitative_research_40: 'Ethnography research',
  qualitative_research_41:
    'Ethnography is a type of qualitative research that involves immersing yourself into a particular community or organization to observe their behavior and interactions up close.',
  qualitative_research_42:
    'Ethnography is a flexible research method that allows you to gain a deep understanding of a group’s shared culture, conventions, and social dynamics. However, it also involves some practical and ethical challenges.',
  qualitative_research_43: 'Methods:',
  qualitative_research_44:
    'Ethnography is a study of the consumer in real-life conditions and interaction with a product/service',
  qualitative_research_45: 'Benefits of working with us',
  qualitative_research_46:
    'We can find respondents from any target audience. And we control the quality of recruiting',
  qualitative_research_47: 'Recruiting of any kind of complexity',
  qualitative_research_48:
    'Our moderators are multilingual and have facilitation skills',
  qualitative_research_49: 'Expert moderators',
  qualitative_research_50: 'Working online/offline',
  qualitative_research_51: 'We provide audio/video',
  qualitative_research_52: 'recordings',
  qualitative_research_53:
    'We can conduct qualitative research in any locality',
  quantitative_research_1: 'Study of customer satisfaction',
  quantitative_research_2: 'with a particular product or service (SCI, NPS)',
  quantitative_research_3: 'Conducting segmentation',
  quantitative_research_4: 'surveys of different markets',
  quantitative_research_5: 'Market research',
  quantitative_research_6: 'NPS management',
  quantitative_research_7: 'Competitor analysis',
  quantitative_research_8: 'Quantitative',
  quantitative_research_9: 'researches',
  quantitative_research_10:
    'Research methodology which uses to test theories about people’s attitudes and behaviors based on objectival, numerical and statistical data',
  quantitative_research_11: 'years',
  quantitative_research_12: 'of CX experience worldwide',
  quantitative_research_13: 'panel',
  quantitative_research_14: 'of respondents',
  quantitative_research_15: 'countries',
  quantitative_research_16: 'we run projects all over the world',
  quantitative_research_17: 'Who we are',
  quantitative_research_18:
    '4Service is a global marketing research and CX holding. We offer expert data collection services to large variety of businesses',
  quantitative_research_19: '4Service By Numbers',
  quantitative_research_20: 'Computer Assisted',
  quantitative_research_21: 'Web Interviewing',
  quantitative_research_22:
    'An Internet-based questionnaire technique. The respondent completes an electronic questionnaire without the help of an interviewer. The questionnaire can be sent electronically or posted on the website.',
  quantitative_research_23: 'Computer Assisted Web Interviewing',
  quantitative_research_24: 'In most cases',
  quantitative_research_25:
    'this method is the most cost-effective in terms of material and time costs',
  quantitative_research_26: 'This survey provides',
  quantitative_research_27:
    'an opportunity to interview an audience that is difficult to reach',
  quantitative_research_28: 'It provides extensive possibilities',
  quantitative_research_29:
    'for video and audio material demonstration as well as pictures',
  quantitative_research_30: 'Respondents can be',
  quantitative_research_31: 'located anywhere in the world',
  quantitative_research_32: 'It allows to investigate',
  quantitative_research_33:
    'the specific behaviour of the Internet audience. The main results can be accessed by the client in real time via a web interface',
  quantitative_research_34: 'Eligible for any confidential,',
  quantitative_research_35:
    'sensitive, personal topic or issue that respondents may have been reluctant to answer when talking to an interviewer',
  quantitative_research_36: '150 000+ panel',
  quantitative_research_37: 'We have our own panel of respondents worldwide',
  quantitative_research_38: 'We work with challenging enquiries',
  quantitative_research_39:
    'By using complementary tools (social media, advertising), we can find a specific audience and a sophisticated profile of the respondent',
  quantitative_research_40: 'We motivate our respondents',
  quantitative_research_41:
    'We have designed a framework for encouraging the completion of long surveys by providing material incentives',
  quantitative_research_42: 'Face-to-face interviews',
  quantitative_research_43: 'conducted by using tablet',
  quantitative_research_44:
    'This is one of the main methods of collecting quantitative data, during which the interviewer communicates directly with the respondent on a strictly structured questionnaire.',
  quantitative_research_45:
    "Face-to-face interviews can be conducted at the respondents' place of work or residence, on the street or atdesignated sales venues using a tablet",
  quantitative_research_46: 'Face-to-face interviews conducted by using tablet',
  quantitative_research_47: 'Determining the level',
  quantitative_research_48: 'of popularity and brand recognition',
  quantitative_research_49: 'Consumer segmentation',
  quantitative_research_50: 'based on their buying behaviour',
  quantitative_research_51: 'Analysing the effectiveness',
  quantitative_research_52: 'of the advertising campaign',
  quantitative_research_53: 'Customer satisfaction',
  quantitative_research_54: 'measurement',
  quantitative_research_55: 'Determining the best price',
  quantitative_research_56: 'for the product or service',
  quantitative_research_57: 'Analysis',
  quantitative_research_58: 'of consumer preferences',
  quantitative_research_59: 'Study',
  quantitative_research_60: 'of how consumers buy',
  quantitative_research_61: 'Assessment',
  quantitative_research_62: 'of market capacity',
  quantitative_research_63: 'DigSee Software',
  quantitative_research_64:
    'We use special software with audio recording and GPS',
  quantitative_research_65: 'Interviewers and supervisors',
  quantitative_research_66:
    "we can conduct interviews in any language and translate into the client's preferred language",
  quantitative_research_67: 'Sampling',
  quantitative_research_68:
    'The analytical department develops a system of respondent recruitment in order to obtain the best possible result',
  quantitative_research_69: 'Computer Assisted',
  quantitative_research_70: 'Telephone Interviewing',
  quantitative_research_71:
    'Methodology for collecting quantitative information through telephone interviews using a clearly structured questionnaire.',
  quantitative_research_72: 'Computer Assisted Telephone Interviewing',
  quantitative_research_73: 'We have our own call centers',
  quantitative_research_74:
    'We use special software with audio recording and GPS',
  quantitative_research_75: 'Qualified operators',
  quantitative_research_76:
    'Our operators speak different languages, are trained, well-tested and continually improve their skills',
  quantitative_research_77: 'Databases',
  quantitative_research_78:
    'We work with our own databases and your customer bases as well',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'We guarantee quality and sign up an SLA (Service Level Agreement)',
  quantitative_research_81: 'Goal setting',
  quantitative_research_82: 'Methodology determination',
  quantitative_research_83: 'Segmentation and sampling',
  quantitative_research_84: 'Defining the data collection channels',
  quantitative_research_85:
    'Creating a questionnaire that is logical for the respondent',
  quantitative_research_86: 'Data collecting process',
  quantitative_research_87: 'Quality assurance',
  quantitative_research_88: 'Analysis techniques',
  quantitative_research_89: 'Data processing',
  quantitative_research_90: 'Survey design',
  quantitative_research_91: 'GPS location tracking of interviewers',
  quantitative_research_92: 'Data verification',
  quantitative_research_93: 'Questionnaire programming and testing',
  quantitative_research_94: 'Data validation',
  quantitative_research_95:
    'Audio recordings while following the requirements of the GDPR',
  quantitative_research_96: 'Training and competence of research staff',
  quantitative_research_97: 'Quality Assurance',
  quantitative_research_98: 'Factor analysis',
  quantitative_research_99: 'Segmentation',
  quantitative_research_100: 'Perceptual mapping',
  quantitative_research_101: 'Correlation',
  quantitative_research_102: 'Conversion model',
  quantitative_research_103: 'Jaccard',
  quantitative_research_104: 'Cluster analysis',
  quantitative_research_105: 'etc.',
  quantitative_research_106: 'Analytical Reports',
  quantitative_research_107:
    'We use SPSS® and other tools to build analytical reports using different methodologies',
  quantitative_research_108: 'What business challenges сan be solved:',
  quantitative_research_109: 'What business challenges сan be solved:',
  social_responsibility_1: '4Service Corporate Social Responsibility',
  social_responsibility_2:
    'From the heart and soul of every member of our team. We are trying to keep a spark of kindness in each of our employees and make the world around us a little better',
  social_responsibility_3: 'Educational activities / Youth support',
  social_responsibility_4: 'Our outreach initiatives for children and students',
  social_responsibility_5: 'Service Mania business game',
  social_responsibility_6:
    'One important part of our training activities is the business game Service Mania.',
  social_responsibility_7:
    'Service Mania challenges you to think strategically about how to deal with unusual situations.',
  social_responsibility_8: 'Research presentation / Diiya. Business',
  social_responsibility_9:
    'A business and customer perspective on the service situation: a presentation of our 4Service research findings by Victoria Skorbota, the Chief of Development at 4Service Ukraine',
  social_responsibility_10: 'Service Design sessions',
  social_responsibility_11:
    'The Service Design methodology is an important part of 4Service. A couple of years ago we became a DesignThinkers Academy partner and we not only use best practices in our work but also actively deliver training to the best companies',
  social_responsibility_12: 'Charity / Volunteering',
  social_responsibility_13:
    'Orphanages, care homes for the elderly and volunteering for the Ukrainian army. The most touching part of our activities',
  social_responsibility_14: '#HelpEasyWith4ServiceGroup',
  social_responsibility_15:
    'We work in partnership with the Blagomai Charity Fund and try to bring celebration and help those who are in dire need',
  social_responsibility_16: 'Fundraising for orphanages and retirement homes',
  social_responsibility_17:
    'We at 4Service have initiated several fundraisers for retirement homes and orphanages to buy basic necessities',
  social_responsibility_18: '#SaveUkraine',
  social_responsibility_19:
    'The war has affected many of our staff and close friends. Each of us helps as much as we can',
  social_responsibility_20: 'Animal welfare',
  social_responsibility_21:
    'Anyone can come to our office with their pet. We help shelters, raise funds for food and sometimes also look for homes for homeless animals',
  social_responsibility_22: 'Supporting animal shelters',
  social_responsibility_23:
    'Homeless animals need help just as much as all vulnerable members of our society.',
  social_responsibility_24:
    'Our company has taken the Sirius Stray Animal Shelter (Kyiv region, Ukraine) into our care.',
  social_responsibility_25: 'Creative initiatives to help stray animals',
  social_responsibility_26:
    'How can we bring the urgent issue of animal shelters to the attention of our customers and partners?',
  social_responsibility_27: 'Pet friendly office',
  social_responsibility_28:
    'Many of our team members have pets. We have created a healthy environment where everyone can come into the office with their pets and feel comfortable',
  ux_testing_1: 'UX Testing',
  ux_testing_1_1: 'UX Testing',
  ux_testing_2:
    'Human and AI-based approach to testing and improving your websites and applications',
  ux_testing_3: 'What is UX testing',
  ux_testing_4:
    'UX testing helps you to understand how people interact with your product, app or website',
  ux_testing_5: 'The navigation is not very clear',
  ux_testing_6: 'I have no idea how to make an order',
  ux_testing_7: 'Not enough photos in the catalogue',
  ux_testing_8: 'You will:',
  ux_testing_9: 'Discover opportunities to improve',
  ux_testing_10: "Identify problems in the design and customer's interaction",
  ux_testing_11:
    'Find out what difficulties the user encounters when interacting with the site',
  ux_testing_12: 'Observe the customer journey',
  ux_testing_13:
    'Recognize when and why does your target audience leave the site',
  ux_testing_14:
    'Define which sections arouse the least and the highest interest and emotion',
  ux_testing_15: 'User-based UX testing',
  ux_testing_16:
    '4Service provides you with the real human insights you need to make customer-centric websites and apps using qualitative and quantitative methods',
  ux_testing_17:
    "I don't understand how to use the site. Where the menu is hidden?",
  ux_testing_18:
    'I think there is too much space between the letters, the text is unreadable',
  ux_testing_19:
    "The pictures overlap each other Is this the way it's supposed to be?",
  ux_testing_20:
    'UX testing helps you to understand how people interact with your product, app or website',
  ux_testing_21: 'User-based UX testing research design:',
  ux_testing_22: 'Discover opportunities to improve',
  ux_testing_23:
    'Determining the type and method of testing (qualitative/quantitative)',
  ux_testing_24: 'Drafting of hypotheses and user scenarios',
  ux_testing_25: 'Analysis and reporting of test results',
  ux_testing_26: 'Two solutions',
  ux_testing_27: 'We offer two approaches to successful UX testing',
  ux_testing_28: 'User-based UX testing',
  ux_testing_29: 'AI + human-based UX testing',
  ux_testing_30: 'Our strengths:',
  ux_testing_31: 'We can analyse the competitive landscape',
  ux_testing_32:
    'We can bring in narrow experts to conduct and participate in interviews',
  ux_testing_33:
    'We can conduct interviews in different languages and translate them into the requireв language',
  ux_testing_34: 'with existing usability',
  ux_testing_35: 'metrics or standards',
  ux_testing_36: 'your performance',
  ux_testing_37: "against a competitor's",
  ux_testing_38: 'versions',
  ux_testing_39: 'of the same product',
  ux_testing_40: 'Satisfaction rating',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Success rates',
  ux_testing_43: 'Error rate',
  ux_testing_44: 'Task completion time',
  ux_testing_45: 'User-based UX testing',
  ux_testing_46: 'Quantitative method',
  ux_testing_47:
    'We use at least 100 people to participate in UX testing for this stage',
  ux_testing_48: 'Relevant when you need a comparison of your website or app:',
  ux_testing_49: 'Quantitative method measure:',
  ux_testing_50: 'Observation',
  ux_testing_51:
    'Spending time with a user or group of users and observing their behavior with the product as they use it in day-to-day life',
  ux_testing_52: 'In-depth interview',
  ux_testing_53:
    'Allow you to learn the attitudes, beliefs, desires, and experiences of the users who come to your site. We recommend conducting 15-20 interviews',
  ux_testing_54: 'Ethnographic research',
  ux_testing_55:
    'Conducted in the environment of respondents where they will be using the product. By observing, you begin to have a good understanding of the psychology of the user, the emotional challenges they face.',
  ux_testing_56: 'User-based UX testing',
  ux_testing_57: 'Qualitative method',
  ux_testing_58:
    'This method helps to understand the motivations and logic of users behavior',
  ux_testing_59: 'We use:',
  ux_testing_60:
    "We're developing a customer journey map to illustrate the results of our research",
  ux_testing_61: '30 participants (male/female)',
  ux_testing_62: 'Respondents pre-screen and invite them to take the survey',
  ux_testing_63: 'Remote online interviewing on the Wantent platform',
  ux_testing_64: 'AI + human based',
  ux_testing_65: 'AI + human-based UX testing',
  ux_testing_66:
    'A combination of unique artificial intelligence technology Wantent and real users',
  ux_testing_67: 'The large font in the headline have attracted attention',
  ux_testing_68:
    "Users look at the 'loud-speaking' numbers, but do not read the information next to them",
  ux_testing_69:
    'UX testing helps you to understand how people interact with your product, app or website',
  ux_testing_70: 'AI + human-based testing research design',
  ux_testing_71: 'How it works',
  ux_testing_72: 'Wantent is an AI-based content-market fit solution.',
  ux_testing_73:
    'Wantent estimates the efficiency of websites and apps by applying ML technologies to analyze the audience’s emotional reactions and engagement.',
  ux_testing_74:
    'To interpret the results, we use storytelling techniques, diversity, and empathy, to drive performance and growth.',
  ux_testing_75:
    "The user's attention and emotions during transitions through sections",
  ux_testing_76:
    'Statistics by participant group (attention and focus throughout the session)',
  ux_testing_77: 'Analysis of page conversions',
  ux_testing_78: 'Attention and emotions during tasks performing',
  ux_testing_79: 'Findings on the perception',
  ux_testing_80: 'of the website by different groups of participants',
  ux_testing_81: 'Analysis of the ease',
  ux_testing_82:
    'of navigating through pages and the ease of finding important elements',
  ux_testing_83: 'A perception analysis',
  ux_testing_84: 'of the information provided on the website',
  ux_testing_85: 'Definition areas',
  ux_testing_86:
    'of improvement within individual pages (navigation, information blocks, etc.)',
  ux_testing_87: 'We analyse in detail',
  ux_testing_88: 'Key results of the research',
  ux_testing_89:
    'Recommendations for improving the structure of the website and individual pages',
  ux_testing_90:
    'We carefully select the target audience for Usability Testing.',
  ux_testing_91:
    'We conduct research in different countries, different languages and among different user groups',
  ux_testing_92: 'We can test in applications',
  ux_testing_93:
    "and on competitors' websites and provide comparative analysis",
  ux_testing_94: 'We combine different research methods.',
  ux_testing_95:
    'Our qualified facilitators guide the participant through the test process.',
  ux_testing_96: 'We conduct testing',
  ux_testing_97: 'on a variety of devices and software versions',
  ux_testing_98: 'Why choose us?',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    'A unique partner project from 4Service and Wantent © A combination of artificial intelligence and a human-based approach for content analysis',
  wantent_4: 'measuring success before the content is aired',
  wantent_5: 'mitigating the risks of diversity issues',
  wantent_6: 'deep insights and recommendations to improve the content',
  wantent_7: 'objective decisions on content and marketing strategy',
  wantent_8: 'Wantent is an AI-based content-market fit solution.',
  wantent_9:
    'Wantent is an artificial intelligence-based solution for matching content to the market. Wantent evaluates the effectiveness of video content by applying ML technologies to analyse emotional response and audience engagement.',
  wantent_10:
    'Wantent helps to define content-market fit and maximize content efficiency by',
  wantent_11: 'Scenario setup & participant recruitment',
  wantent_12: 'personalized project design and audience worldwide',
  wantent_13: 'Gathering viewers’ reactions',
  wantent_14: 'detailed and clear feedback from the audience',
  wantent_15: 'Analysis of the content perception',
  wantent_16: 'behavior and emotional reaction analysis by AI',
  wantent_17: 'Delivery of insights for final wrap up',
  wantent_18: 'recommendations for improvements',
  wantent_19: 'Wantent maximizes your content efficiency',
  wantent_20: 'Ad concept pre-testing',
  wantent_21: 'Creative concept testing',
  wantent_22:
    'Checking the consistency of future creative with the developed strategy and understanding the reactions of the target audience for possible adjustments to the creative concepts/messages',
  wantent_23: 'Viewability testing of the brand elements (post-production)',
  wantent_24:
    'Confirmation of the effectiveness of the advertising/sponsorship investment',
  wantent_25: 'Ad testing',
  wantent_26: 'A/B testing',
  wantent_27:
    'Comparing the effectiveness of creatives and increasing engagement and attention during the production phase',
  wantent_28: 'Launched ad testing',
  wantent_29:
    'Defining the messages performance and creating recommendations for improving audio and visuals',
  wantent_30: 'Solutions for TV',
  wantent_31: 'Pilots, promos, new formats, TV presenters testing',
  wantent_32:
    'Conducting a comparative analysis to identify the most engaging one and strengthen it',
  wantent_33: 'TV show and feature film testing',
  wantent_34:
    'Analyzing the viewers’ involvement, attention level, emotional response during the watching long content in natural conditions. We automatically find the peaks of the audience dump and moments of distraction to evaluate and improve the video mounting',
  wantent_35: 'UI/UX testing',
  wantent_36: 'Website prototypes and mobile applications testing',
  wantent_37:
    'Analysis of user reaction and emotions using Wantent technology:',
  wantent_38: 'level of attention',
  wantent_39: 'emotional reactions',
  wantent_40: "heatmaps of participants' gaze direction",
  wantent_41:
    'Website and application usability analysis based on participant feedback:',
  wantent_42: 'System usability scale (SUS)',
  wantent_43: 'Client Effort Score (Ease of Interaction)',
  wantent_44: 'Net Promotion Score (NPS)',
  wantent_45: 'GDPR complience and user privacy',
  wantent_46:
    'Wantent is fully compliant with the GDPR and CCPA policies and meets the principles of collection, storage, processing, and protection of the participants’ personal data',
  wantent_47: 'Nikita Lobyntsev',
  wantent_48: 'СDO, Reface (Media & Entertainment)',
  wantent_49:
    'We experiment with tons of new content ideas with machine-learning technologies every day. Wantent helps us understand and evaluate risks and advantages, discover users’ feedback, behaviour and perception to get causality of different aspects of our solutions.',
  wantent_50: 'Alexander Smirnov',
  wantent_51: 'co-owner TABASCO (Advertising)',
  wantent_52:
    'Wantent provides a truly unique service that helps us know very minor details of what consumers like and dislike. There is no way to cheat, there is no way to lie - Wantent always knows the truth, and hereby we as marketing experts get the priceless (hahaha - we know the exact price and it is affordable) guidelines on how to improve our communications.',
  wantent_53: 'Reviews',
  terms_1: 'Terms & conditions',
  terms_2: 'Terms of Use Agreement',
  terms_3:
    'Please read these Terms of Use Agreement (“terms of use”, “agreement”) carefully before using website',
  terms_4:
    '(“website”) operated by 4Service Holdings GmbH ( “4Service”, “us”, ‘we”, “our”).',
  terms_5:
    'Conditions of useBy using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. 4Service only grant use and access of this website, its products, and its services to those who have accepted its terms.',
  terms_6: 'Privacy Policy',
  terms_7:
    'Before you continue using our website, we advise you to read our Privacy Policy regarding ouruser data collection. It will help you better understand our practices.',
  terms_8: 'Age restriction',
  terms_9:
    'You must be at least 16 (sixteen) years of age before you can use this website. By using this website, you warrant that you are at least 16 (sixteen) years of age and you may legally adhere to this Agreement. 4Service assumes no responsibility for liabilities related to age misrepresentation.',
  terms_10: 'Intellectual property',
  terms_11:
    'You agree that all materials, products, and services provided on this website are the property of 4Service, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the 4Service’s intellectual property in any way, including electronic, digital, or new trademark registrations.',
  terms_12: 'Accessing our website',
  terms_13:
    'Access to our Site is permitted on a temporary basis, and we reserve the right to withdraw or amend the service we provide on our site without notice. We will not be liable if for any reason our site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of our site, or our entire site, to users who have registered with us. You are responsible for making all arrangements necessary for you to have access to our site including the use of equipment which is compatible with our site. You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms, and that they comply with them.',
  terms_14: 'Applicable law',
  terms_15:
    'By visiting this website, you agree that the laws of the Republic of Austria, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between 4Service and you.',
  terms_16: 'Disputes',
  terms_17:
    'Any dispute related in any way to your visit to this website shall be arbitrated by Commercial court of Vienna.',
  terms_18: 'Indemnification',
  terms_19:
    'You agree to indemnify 4Service and its affiliates and hold 4Service harmless against legal claims and demands that may arise from your use or misuse of our website. We reserve the right to select our own legal counsel.',
  terms_20: 'The period of storage',
  terms_21:
    'Taking into account the purposes of processing, the period of storage of the Users’ personal data (period of storage) is 24 months since the date the data processing consent is duly obtained from You.',
  terms_22: 'Limitation on liability',
  terms_23:
    '4Service is not liable for any damages that may occur to you as a result of your misuse of our website. 4Service reserves the right, without notice, to edit, modify, and change this Agreement any time by updating this posting. Your continued use of the website now, or following the posting of any changes or modifications, will indicate acceptance by you of such changes or modifications. If any part of this agreement is declared unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of any remaining provisions. Possible evidence of use of the website for illegal purposes will be provided to law enforcement authorities. This Agreement is an understanding between 4Service and the user.',
  terms_24:
    'Please direct all privacy/usage related questions and concerns to the following address:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstrasse 7',
  terms_27: '1010 Vienna',
  terms_28: 'Austria',
  terms_29: 'Review',
  terms_30:
    'When the personal data You provide us with are not inaccurate then, You are entitled to ask us to correct them indeed (GDPR Article 16).',
  thank_you_1: 'Thank you!',
  thank_you_2: 'We will get back to you within 24 hours',
  thank_you_3:
    'P.S. Our managers are already reviewing your request to put together a better offer',
  thank_you_4: 'Menu',
  thank_you_5: 'Woman typing',
  privacy_policy_1:
    'Privacy notice form for data subjects (users of the web-site)',
  privacy_policy_2:
    'This Privacy Notice Form (Privacy Notice) is intended to natural persons (Data subjects) whose personal data are collected by 4Service Holdings GmbH, in line with the requirements of the GDPR in frames of visiting of the web-site',
  privacy_policy_3:
    '(here in after Web-site and Users of the Web-site/Users respectively).',
  privacy_policy_4:
    'Privacy Notice Form For Data Subjects (Users Of The Web-Site) of 4Service Holdings GmbH is applied in all companies of the 4Service Group, which are under the legal authority under the supervision of or controlled by 4Service Holdings GmbH.',
  privacy_policy_5: 'Contacts',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Company/we/us),',
  privacy_policy_8: 'Address:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Vienna, Austria.',
  privacy_policy_10: 'E-mail:',
  privacy_policy_12: 'Who we are?',
  privacy_policy_13:
    'An Individual (You) who visits the Website with any purpose. For the purpose of this Document You shall be also indicated as User.',
  privacy_policy_14: 'Personal data we collect from you',
  privacy_policy_15:
    'When you enter to our Web-site, we send You a Privacy notice, in which we offer ask you to provide us a consent for processing of the following information:',
  privacy_policy_16:
    'IP address, user’s name, surname, address, phone number (landline or mobile), e-mail address, address, company name, country, e-mail address, first name, last name, phone number, province, state and ZIP/Postal code, usage data, data about interaction with external social networks or platforms, information about registration and auntification on the web-site',
  privacy_policy_17: 'geographic position.',
  privacy_policy_18:
    'In case, you express your will and give us such consent, we start to process such information from you.',
  privacy_policy_19: 'Lawfulness (consent)',
  privacy_policy_20:
    'When you enter to our Web-site, we send You a Privacy notice, in which we offer ask you to provide us a consent for processing of the following information:',
  privacy_policy_21:
    'The legal basis for processing for the personal data is consent, which we are going to get from You through filling in the consent form at the following link:',
  privacy_policy_22:
    'If You fill in the consent form, this means that You understand and accept all conditions specified in this Privacy Notice.',
  privacy_policy_23: 'Withdrawal of consent',
  privacy_policy_24:
    'You have a right to withdraw the consent You has provided us earlier at any time. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal. You may withdraw your consent through sending us the appropriate request at the following e-mail address withdraw@4service-group.com, herewith, the form of the request is available at the following link:',
  privacy_policy_25: 'Withdrawal Form for User',
  privacy_policy_26: 'Purposes of processing',
  privacy_policy_27:
    'We process your personal data for the following purposes:',
  privacy_policy_28:
    '– improve client services (it allows a more effective response to the requests from clients); – personalization of Users’ experience (it allows to determine who is more interesting in services); – improve the Site (it allows to improve the quality of products and services, convenience of their use, develop new Services enhance our products and services); – communicate with User with newsletters, marketing or promotional materials and other information that include our news, updating, information about services with the remark on the instruction how to refuse from receiving follow-up e-mails; – carry out statistical and other kinds of research and analysis based on anonymized data; – provide personalized services to the User and perform agreements and contracts; – participate of the User in various projects implemented by us through the Site, responding to queries addressed by User through the Site, research, maintenance of accounts and records and the promotion of services.',
  privacy_policy_29: 'Subjects the personal data may be transferred to',
  privacy_policy_30:
    'While processing your personal data, we transfer your personal data to the entities which act as the Company’s processors. The Company’s processors act exclusively on the basis of the Company’s instructions. The specifics of actions the Company’s processors conduct and list of such processors can be found in the Privacy and Data Protection Policy at the following link: Privacy and Data Protection Policy',
  privacy_policy_31: 'The countries the personal data may be transferred to',
  privacy_policy_32:
    'The Company transfers your personal data on the basis of an adequacy decision as its stipulated by the GDPR and EU Commission. Additional information regarding personal data transfer to the USA can be found in the Privacy and Data Protection Policy at the following link: Privacy and Data Protection Policy.',
  privacy_policy_33: 'The period of storage',
  privacy_policy_34:
    'Taking into account the purposes of processing, the period of storage of the Users’ personal data (period of storage) is 24 months since the date the data processing consent is duly obtained from You.',
  privacy_policy_35: 'Right of access',
  privacy_policy_36:
    'You have a right to know whether personal data concerning You are being processed and 2) if so, access such data with loads of additional stipulations stated in the GDPR Article 15.',
  privacy_policy_37: 'Right to rectification',
  privacy_policy_38:
    'When the personal data You provide us with are not inaccurate then, You are entitled to ask us to correct them indeed (GDPR Article 16).',
  privacy_policy_39: 'Right to erasure (right to be forgotten)',
  privacy_policy_40:
    'You have a right to obtain from us the erasure of your personal data without undue delay and we shall have the obligation to erase your personal data without undue delay where the grounds, stated in the GDPR Article 17 applies.',
  privacy_policy_41: 'Right to restriction of processing',
  privacy_policy_42:
    'You have a right to limit processing of your personal data with several exceptions under the scope of the GDPR in particular stated in the GDPR Article 18.',
  privacy_policy_43:
    'We are obliged to inform You what data are being collected, how they are being used, how long they will be kept and whether they will be shared with any third parties. This information must be communicated concisely and in plain language.',
  privacy_policy_44: 'Right to data portability',
  privacy_policy_45:
    'You are permitted to obtain and reuse your personal data for your own purposes across different services.',
  privacy_policy_46: 'Right to object',
  privacy_policy_47:
    'You have a right to object the processing of personal data that are being processed by the Company. We must stop processing personal data unless we demonstrate compelling legitimate grounds for the processing that overrides the interests, rights and freedoms of the individual or if the processing is for the establishment or exercise of defense of legal claims.',
  privacy_policy_48:
    'Right not to be subject to a decision based solely on automated processing',
  privacy_policy_49:
    'You have a right to object to any automated profiling that is occurring without consent. Herewith, the You have a right your personal data are to be processed with the human involvement.',
  privacy_policy_50: 'Complaints',
  privacy_policy_51:
    'In the event that You wish to make a complaint about how your personal data are being processed by the Company (or by processors, described above), or how your complaint has been handled, You have the right to lodge a complaint directly with the supervisory authority and the Company.',
  privacy_policy_52: 'The details for each of these contacts are:',
  privacy_policy_53: 'Supervisory authority:',
  privacy_policy_54: 'The Austrian Data Protection Authority',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Vienna',
  privacy_policy_58: 'Austria / Europe',
  privacy_policy_59: 'Company:',
  privacy_policy_60: '4Service Holdings GmbH (Company/we/us),',
  privacy_policy_61: 'Address: Tegetthoffstraße 7, 1010 Vienna, Austria.',
  privacy_policy_62: 'E-mail:',
  privacy_policy_63: 'Privacy and data protection policy',
  privacy_policy_64:
    'Read more about how and why we use your data here: Privacy and Data Protection Policy',
  privacy_policy_65: 'Approval',
  privacy_policy_66:
    'The Company has developed all internal documents to define roles among staff concerning the personal data processing within the Company, in particular responsible for approving and reviewing the legitimacy of this document is the Managing Director.',
  contacts_1: 'For clients',
  contacts_2:
    'For all kinds of businesses, market research agencies and big companies, business partners and media.',
  contacts_3: 'Tegetthoffstrasse 7, Vienna, Austria',
  contacts_4: 'For Shoppers',
  contacts_5:
    'Locals and travelers, drivers and students, professional mystery shoppers and homebodies.',
  contacts_6: 'Contact us from your country:',
  contacts_7: 'Italy',
  contacts_8: 'USA',
  contacts_9: 'UK',
  contacts_10: 'Switzerland',
  contacts_11: 'Netherlands',
  contacts_12: 'Slovakia',
  contacts_13: 'Slovenia',
  contacts_14: 'Romania',
  contacts_15: 'AZ',
  contacts_16: 'Ukraine',
  contacts_17: 'Kazakhstan',
  contacts_18: 'Rest of World',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'UX testing',
  menu_6: 'HR research',
  menu_7: 'Qualitative research',
  menu_8: 'Quantitative research',
  menu_9: 'Mystery shopping',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Price monitoring',
  menu_14: 'About us',
  menu_15: 'Contacts',
  menu_16: 'Blog',
  menu_17: 'Methods',
  menu_18: 'Products',
  menu_19: 'Shoppers \n platform',
  menu_20: 'CSR',
  menu_21: 'Automotive price analysis',
  menu_22: 'Facility Check Audit',
  menu_23: 'Service Design',
  menu_24: 'CX',
  nps_passives: 'Passives',
  nps_detractors: 'Detractors',
  nps_promoters: 'Promoters',
  nps_a_score: 'a score',
  area: 'Area',
  products_tabs_1:
    'In order to achieve maximum efficiency, we also carry out in complex:',
  products_tabs_2: 'Products',
  products_tabs_3:
    'Qualitative research is collecting and analyzing non-numerical data to understand concepts, opinions, or experiences',
  products_tabs_4:
    'Quantitative research methods is an objective measurement and numerical analysis of data collected through polls, questionnaires, and surveys',
  our_clients_1: 'Our clients',
  preview_cases_section_1: 'Cases',
  preview_cases_section_2: 'Go to the cases to read more interesting posts',
  blog_1: 'Rousing content blog',
  blog_2: 'No posts available for your request',
  about: 'About',
  why_us: 'Why us',
  about_us: 'About us',
  key_features: 'Key features',
  solutions: 'Solutions',
  monitoring: 'Monitoring',
  how: 'How',
  how_it_works: 'How it works',
  when_to_use: 'When to use',
  mystery_shopping: 'Mystery shopping',
  voicer: 'Voice of customer',
  wantent: 'Video content testing',
  play4sales: 'Play4Sales',
  price_monitoring: 'Price monitoring',
  service_design: 'Service design',
  qualitative_research: 'Qualitative research',
  quantitative_research: 'Quantitative research',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Automotive',
  hashtag_retayl: '#Retail',
  hashtag_all_posts: '#All_posts',
  hashtag_feedback: '#feedback',
  hashtag_4Service_csr: '#4Service_CSR',
  hashtag_cases: '#Cases',
  hashtag_market_research: '#market_research',
  more: 'More',
  speak_to_an_expert: 'Speak to an expert',
  book_consultancy: 'Book consultancy',
  read_all: 'Read all',
  find_out_more: 'Find out more',
  address: 'Address',
  mon_fri: 'Mon-Fri',
  phone_number: 'Phone number',
  flag: 'flag',
  scroll_to_left: 'scroll to left',
  online: 'online',
  tel: 'Tel',
  email: 'E-mail',
  light_it_up: 'Light it up',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Director of business development',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Senior project manager',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Business Development Manager',
  about_us_66: 'Zhelevskiy Dmitriy',
  about_us_67: 'Operations Director',
  about_us_roles_1: 'Management',
  about_us_roles_2: 'Team Global',
  about_us_roles_3: 'Product owners',
  ad_testing_1: 'Ad Testing',
  ad_testing_2:
    'An advertising campaign is a huge investment. Make sure that your ads are powerful and deliver maximum ROI',
  ad_testing_3: 'lock',
  ad_testing_4: 'Industries we serve',
  ad_testing_5: '4Service is a global customer experience research holding',
  ad_testing_6:
    '4Service is global marketing research and CX holding. We offer expert data collection services to large variety of businesses',
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Retail',
  ad_testing_9: 'Electronics',
  ad_testing_10: 'Luxure/Premium',
  ad_testing_11: 'Dining & Hospitality',
  ad_testing_12: 'Automotive',
  ad_testing_13: 'Travel & Leisure',
  ad_testing_14: 'Fashion',
  ad_testing_15: 'Banks',
  ad_testing_16: 'Watches & Jewelry',
  ad_testing_17: 'Household & Appliance',
  ad_testing_18: 'Esomar member',
  ad_testing_19:
    'We have conducted Ad Testing research on advertising for various businesses in different areas',
  ad_testing_20: 'You need Ad Testing if you are',
  ad_testing_21: 'Promoting a new product or service',
  ad_testing_22: 'Touching on a sensitive subject',
  ad_testing_23: 'Trying to reach a new target demographic or market',
  ad_testing_24: 'Discussing several different design options',
  ad_testing_25: 'Need a proof of concept for management teams or investors',
  ad_testing_26: 'You will get answers to the questions',
  ad_testing_27: 'Which advertising channels are best suited to these ads?',
  ad_testing_28: 'Are we using the right visuals to get the message across?',
  ad_testing_29: 'Do your advertising texts and messages resonate?',
  ad_testing_30: 'Which advertising channels are best suited to these ads?',
  ad_testing_31: 'What catches their attention in the first place?',
  ad_testing_32: 'Is the placement and layout of the ad easy to understand?',
  ad_testing_33: 'We evaluate',
  ad_testing_34: 'Branding',
  ad_testing_35:
    'how your brand is linked to advertising and how much it will be remembered',
  ad_testing_36: 'Key Metrics',
  ad_testing_37: 'Integration',
  ad_testing_38: 'Recognition',
  ad_testing_39: 'Fit',
  ad_testing_40: 'Creative',
  ad_testing_41:
    "How your advertisement stands out and catches people's attention",
  ad_testing_42: 'Key Metrics',
  ad_testing_43: 'RECOGNITION',
  ad_testing_44: 'LIKING',
  ad_testing_45: 'MTO',
  ad_testing_46: 'MOTIVATION',
  ad_testing_47: 'Customer experience, emotional engagement',
  ad_testing_48:
    "With the use of artificial intelligence technology and expert analysts, we analyse people's real emotions and get insights into their perceptions of advertising",
  ad_testing_49: 'Rating',
  ad_testing_50: 'Male',
  ad_testing_51: 'Female',
  ad_testing_52: 'Engagement high',
  ad_testing_53: 'Attention',
  ad_testing_54: 'What did the audience like and recall?',
  ad_testing_55: 'Actress',
  ad_testing_56: 'Equality',
  ad_testing_57: 'Musical background',
  ad_testing_58: 'Positive emotions',
  ad_testing_59: 'People of different races and appearance',
  ad_testing_60: 'Outdoor shots',
  ad_testing_61: 'What did the audienceне NOT like and recall?',
  ad_testing_62:
    "The speaker's voice did not match what was happening on the screen",
  ad_testing_63: 'Low attention to the product',
  ad_testing_64: 'Too dynamic',
  ad_testing_65: 'Difficult to comprehend',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Impressions',
  ad_testing_68: 'Shows',
  ad_testing_69: 'Conversion',
  ad_testing_70: 'Media',
  ad_testing_71:
    'We measure the effectiveness of an advertising campaign in conjunction with media activity, calculating the impact of media investments in different channels on the results monitored.',
  ad_testing_72:
    'We conduct advertising research in any format, platform or channel',
  ad_testing_73: 'videocontent',
  ad_testing_74: 'outdoor',
  ad_testing_75: 'TV ads',
  ad_testing_76: 'print',
  ad_testing_77: 'digital',
  ad_testing_78: 'ad campaings',
  ad_testing_79: 'POS display and advertising',
  ad_testing_80: 'animatics',
  ad_testing_81: 'With our help, you can test your advertising at any stage',
  ad_testing_82: 'Concept',
  ad_testing_83: 'which ideas have stronger capacity?',
  ad_testing_84: 'Early-stage production',
  ad_testing_85: 'which animatics will go into production?',
  ad_testing_86: 'Production',
  ad_testing_87: 'how to edit a video so that it can retain attention?',
  ad_testing_88: 'Pre-media',
  ad_testing_89:
    'which communication channels will work and what outcome can be expected?',
  ad_testing_90: 'Ad Post-Testing',
  ad_testing_91: 'Production',
  ad_testing_92:
    'is my advertising captivating? Does the effectiveness of creativity diminish over time?',
  ad_testing_93: 'Pre-media',
  ad_testing_94:
    'how effective is my advertising in numbers? how does it compare with the competition?',
  ad_testing_95: 'Ad Pre-Testing',
  ad_testing_96: 'To provide you with the high-quality data, we use',
  ad_testing_97: 'Quantitative research methodology',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Qualitative research methodology',
  ad_testing_100: 'Focus Groups',
  ad_testing_101: 'AI',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Analytical reports you receive',
  ad_testing_104:
    'Our team of analysts, media experts, marketers will prepare and present you a tailored report with detailed recommendations',
  ad_testing_age: 'age',
  ad_testing_ad: 'Ad',
  automotive_industry_price_analysis_1: 'Automotive Industry Price Analysis',
  automotive_industry_price_analysis_2:
    'Competitive price analysis can help prevent lost profits, increase market share, and monitor your market position. Allow us to assist you in selecting a successful competitive strategy!',
  automotive_industry_price_analysis_3: 'Request a quote',
  automotive_industry_price_analysis_4: 'Our methodology',
  automotive_industry_price_analysis_5: 'Stylish elegant people car salon',
  automotive_industry_price_analysis_6:
    'The Mystery Shopping method is the only approach that accurately reflects a real customer experience and provides a credible overview.',
  automotive_industry_price_analysis_7:
    'We offer precise figures and dependable multilateral data',
  automotive_industry_price_analysis_8:
    'We have global coverage spanning every country in the world',
  automotive_industry_price_analysis_9:
    'Our in-house resources include mystery shoppers and auditors',
  automotive_industry_price_analysis_10: 'We validate the quality of our data',
  automotive_industry_price_analysis_11: 'Our advantages:',
  automotive_industry_price_analysis_12:
    'Price monitoring for specific competitive models (B2B and B2C)',
  automotive_industry_price_analysis_13: 'Monitoring the level of discount',
  automotive_industry_price_analysis_14:
    'Monitoring retailer stock for your brand and competitors',
  automotive_industry_price_analysis_15:
    'Monitoring e-commerce automotive prices',
  automotive_industry_price_analysis_16: 'Price communication analysis',
  automotive_industry_price_analysis_17:
    'Keeping track of retailer shipping charges',
  automotive_industry_price_analysis_18:
    'Monitoring promotions and sales at retailers',
  automotive_industry_price_analysis_19: 'Determining monthly payment amounts',
  automotive_industry_price_analysis_20: 'Calculating lease payments',
  automotive_industry_price_analysis_21:
    'Analyzing specific pricing considerations for electric vehicles (EVs)',
  automotive_industry_price_analysis_22:
    'Conducting direct-to-consumer versus retailer price comparisons',
  automotive_industry_price_analysis_23:
    'Developing subscription pricing models',
  automotive_industry_price_analysis_24:
    '4Service is a global customer experience (CX) holding company with over 20 years of experience',
  automotive_industry_price_analysis_25:
    "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
  automotive_industry_price_analysis_26:
    "4Service's analysis of prices in the automotive industry includes:",
  automotive_industry_price_analysis_27:
    "Lowest prices aren't always the key to competitiveness for car brands. Let us help you create a long-term pricing strategy",
  automotive_industry_price_analysis_28:
    'of customers understand that prices are increasing across all brands',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase,',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'of people consider leasing and monthly payments to be the most preferred model for buying a car for them',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'of dealers mentioned the possibility of price increases during the communication/selling process',
  automotive_industry_price_analysis_35: '47.6',
  automotive_industry_price_analysis_36: 'Key figures',
  automotive_industry_price_analysis_37:
    "You'll figure out how to price your product relative to other vehicles in the lineup.",
  automotive_industry_price_analysis_38:
    "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
  automotive_industry_price_analysis_39:
    'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase',
  automotive_industry_price_analysis_40:
    "You'll be able to determine the appropriate monthly payment and leasing rates.",
  automotive_industry_price_analysis_41:
    'What benefits for your brand or dealer will you gain:',
  automotive_industry_price_analysis_42: 'You submit an application',
  automotive_industry_price_analysis_43:
    'Our manager contacts you to determine the main objectives',
  automotive_industry_price_analysis_44: 'We conduct a marketing research',
  automotive_industry_price_analysis_45:
    'We regularly provide you with reports and recommendations in an online format',
  automotive_industry_price_analysis_46: 'Car with sale sticker',
  automotive_industry_price_analysis_47: 'We launch the project in 1 day!',
  automotive_industry_price_analysis_48:
    'We offer a comprehensive set of reports including photo reports, PPT reports, and Excel reports, segmented by the type of locality',
  retail_audit_1: 'FIELD MARKETING AUDIT/retail audit',
  retail_audit_2:
    'Collect detailed data on your store or brand, analyze growth barriers, and create effective strategies to overcome them',
  retail_audit_3: 'Request a quote',
  retail_audit_4:
    '4Service conducts on-site store audits on behalf of brands to assess the effectiveness of their promotional strategies, field execution teams, and marketing tactics in reaching the intended target market.',
  retail_audit_5:
    'We meticulously observe the execution of promotional campaigns and ensure adherence to the promotional guidelines.',
  retail_audit_6: 'Top 10',
  retail_audit_7: 'CX agencies in Europe',
  retail_audit_8: 'years experience',
  retail_audit_9: 'countries coverage',
  retail_audit_10: '1,6 milion',
  retail_audit_11: 'evaluations per year',
  retail_audit_12: 'What do we track?',
  retail_audit_13: 'Presence of point-of-sale (POS) materials',
  retail_audit_14:
    'Presence and activation of brand ambassadors, ensuring adherence to the planned schedule',
  retail_audit_15:
    'Assessment of brand ambassadors` engagement in advocacy campaigns',
  retail_audit_16:
    'Evaluation of consumer interaction, ensuring compliance with promotional guidelines and brand messaging',
  retail_audit_17: 'Fraud prevention measures',
  retail_audit_18:
    'Your business can use the results of a field marketing audit to:',
  retail_audit_19: 'Assess compliance with budget and processes',
  retail_audit_20: 'Compare actual results with intended objectives',
  retail_audit_21: 'Enhance the performance of marketing campaigns ',
  retail_audit_22: 'Strategically plan for the future',
  retail_audit_23: 'Identify opportunities for cost reduction',
  retail_audit_24: 'Improve sales and conversion rates',
  retail_audit_25: 'Optimize return on investment (ROI)',
  retail_audit_26: 'THEY CHOSE 4SERVICE AS THEIR TRADE INVESTMENT PARTNER',
  retail_audit_27:
    'A leading international tobacco product manufacturer partnered with 4Service to enhance its brands and improve its in-store performance. Our teams provided in field marketing audit and consumer experience live reporting.',
  retail_audit_28:
    'increase in planning accuracy of the field marketing agency',
  retail_audit_29: 'increase in brand message delivering',
  retail_audit_30: 'increase in BA engagement (consumer activation)',
  retail_audit_31: 'increase in data collection accuracy and GDPR compliance',
  retail_audit_32: 'What valuable insights do we tend to find:',
  retail_audit_33:
    'A lack of consistency or even conflict between the terms of the SLA leads to significant unapproved charges being made to the client',
  retail_audit_34:
    'Incorrect charging of fees due to mixing up the types of service being provided',
  retail_audit_35:
    'Field service not being performed including staff not being assigned to planned location | POS',
  retail_audit_36:
    'Charges made on an estimate rather than actual and not reconciled',
  retail_audit_37:
    'Incorrect application of the promo guideline providing fraudulent data',
  retail_audit_38: 'How do we work?',
  retail_audit_39:
    'Our auditor visits designated point-of-sale (POS) locations based on the agencyʼs activation plan.',
  retail_audit_40:
    'They evaluate key performance indicators (KPIs) as outlined in the brief, such as POS presence, brand ambassador engagement, adherence to promotional guidelines, and fraud prevention measures.',
  retail_audit_41: 'Validation:',
  retail_audit_42: 'We gather and consolidate the data obtained.',
  retail_audit_43: 'A comprehensive report is drafted.',
  retail_audit_44:
    'Our validation team thoroughly reviews all surveys for accuracy, including compliance with project brief and any additional comments.',
  retail_audit_45: 'Online Reporting System:',
  retail_audit_46:
    'Completed and validated questionnaires are uploaded to our online portal within 36 hours.',
  retail_audit_47:
    'Clients have access to a personal account portal, enabling them to monitor the progress at any time.',
  retail_audit_48:
    'A final report containing results and recommendations is provided.',
  retail_audit_49: 'Speak to an expert',
  retail_audit_50: 'Our strengths:',
  facility_check_audit_1: 'Facility',
  facility_check_audit_2: 'Check Audit',
  facility_check_audit_3: 'Elevate Brand Experience in Every Detail',
  facility_check_audit_4: 'Explore Our Approach',
  facility_check_audit_5: 'About',
  facility_check_audit_6: 'Who we are',
  facility_check_audit_7:
    'As a global customer experience holding, 4Service provides a comprehensive suite of services to help brands create and deliver exceptional experiences for their customers. ',
  facility_check_audit_8: 'Our Facility Check Audit service',
  facility_check_audit_9:
    'is designed to help you ensure that your physical locations and brand presence at the retail stores are meeting your brand standards and are creating a positive impression for customers.',
  facility_check_audit_10: 'Solutions 1',
  facility_check_audit_11: 'Facility Check Audit',
  facility_check_audit_12:
    "is a meticulous examination that evaluates the alignment of your retail outlets or stands with your brand's standards. Not only do we verify the physical attributes, but we also gauge how these elements influence the way your brand is perceived by your valued customers.",
  facility_check_audit_13: 'Two solutions',
  facility_check_audit_14: 'Two Implementation Methods',
  facility_check_audit_15: 'Method',
  facility_check_audit_16: 'Facility Check Audit by Expert Auditors',
  facility_check_audit_17: 'Validation of Facility Check',
  facility_check_audit_18: 'Reports',
  facility_check_audit_19:
    "You'll get reports, complete with visual evidence and statistics, accessible within a 48 hours",
  facility_check_audit_20:
    'A well-executed Facility Check Audit can provide a number of benefits for your brand, including:',
  facility_check_audit_21: 'Improved customer conversion:',
  facility_check_audit_22:
    'A positive customer experience can lead to increased sales and repeat business. Our audits can help you identify and address any areas that may be impacting customer satisfaction.',
  facility_check_audit_23: 'Enhanced brand image:',
  facility_check_audit_24:
    'Your physical locations are a key touchpoint for customers, and they can play a significant role in shaping your brand image. Our audits can help you ensure that your locations are consistent with your brand values and are creating a positive impression for customers.',
  facility_check_audit_25: 'Why us?',
  facility_check_audit_26: 'Why is Facility Check Audit important?',
  facility_check_audit_27:
    'A well-executed Facility Check Audit can provide a number of benefits for your brand, including:',
  facility_check_audit_28: 'Enhanced brand image:',
  facility_check_audit_29:
    'Your physical locations are a key touchpoint for customers, and they can play a significant role in shaping your brand image. Our audits can help you ensure that your locations are consistent with your brand values and are creating a positive impression for customers.',
  facility_check_audit_30: 'Improved customer conversion:',
  facility_check_audit_31:
    'A positive customer experience can lead to increased sales and repeat business. Our audits can help you identify and address any areas that may be impacting customer satisfaction.',
  facility_check_audit_32: 'Who needs',
  facility_check_audit_33: 'Who needs Facility Check Audit?',
  facility_check_audit_34:
    'Facility Check Audit is a valuable service for any brand with physical locations and physical presence. It is especially important for brands that want to:',
  facility_check_audit_35: 'Ensure compliance with brand standards:',
  facility_check_audit_36:
    'Our audits can help you identify any areas where your locations may be deviating from your brand standards.',
  facility_check_audit_37: 'Improve customer experience:',
  facility_check_audit_38:
    'Our audits can help you identify opportunities to enhance the customer experience at your locations.',
  facility_check_audit_39: 'Build a stronger brand:',
  facility_check_audit_40:
    'Our audits can help you ensure that your physical locations are creating a positive impression for customers.',
  facility_check_audit_41:
    'Your employee or mystery shopper takes a photo of the location.',
  facility_check_audit_42:
    'Then, they fill out a questionnaire and attach the photo.',
  facility_check_audit_43:
    'The validator receives the photo and checks it for compliance with the standards.',
  facility_check_audit_44: 'Facility Check Audit by Expert Auditors',
  facility_check_audit_45: 'Preparation Stage:',
  facility_check_audit_46: 'Collaborative approval of the audit schedule',
  facility_check_audit_47:
    'Dealers are prepped and informed about the forthcoming audit',
  facility_check_audit_48: 'Auditor Training:',
  facility_check_audit_49:
    'Our seasoned 4Service trainers empower auditors with brand guidelines',
  facility_check_audit_50: 'Facility Audit Stage:',
  facility_check_audit_51:
    'Auditors, armed with special authorization letters, visit locations',
  facility_check_audit_52:
    'Thorough examination of each point, meticulously following guidelines',
  facility_check_audit_53:
    'Visual documentation, alongside evaluations against HQ prerequisites',
  facility_check_audit_54: 'Conclusive PPT Report',
  facility_check_audit_55:
    'An all-encompassing PowerPoint report, elucidating findings',
  facility_check_audit_56:
    'Immersive online/offline presentation of the audited results',
  facility_check_audit_57: 'Validation of Facility Check',
  facility_check_audit_58: 'Steps:',
  facility_check_audit_59: 'Facility Audit Stage:',
  facility_check_audit_60:
    'Brand representatives harness the 4Service app to capture on-site pictures',
  facility_check_audit_61:
    'Adherence to stringent visual and descriptive criteria',
  facility_check_audit_62: 'Validation Stage:',
  facility_check_audit_63:
    'Diligent validators meticulously review each report',
  facility_check_audit_64:
    'Comprehensive comparison of photos with brand standards',
  facility_check_audit_65:
    'Annotations and insights integrated into the report for clarity',
  facility_check_audit_66:
    'Direct communication with dealers for clarifications, if needed',
  facility_check_audit_67: 'Reporting Phase:',
  facility_check_audit_68:
    'Reports, complete with visual evidence anc statistics, accessible within two days',
  facility_check_audit_69: 'Contact us today',
  cx_solution_1: "We don't just find insights,",
  cx_solution_2: 'We take your business to new heights',
  cx_solution_3:
    'Experts with over 20 years of customer experience discover complex solutions and provide you with a specific action plan that contributes to your growth',
  cx_solution_4:
    'Discover a unique suite of services in one future-proof solution',
  cx_solution_5: 'All-in-one CX solution',
  cx_solution_6: 'Ad testing',
  cx_solution_7: 'AI & Neuromarketing',
  cx_solution_8: 'Voice of the customer',
  cx_solution_9: 'Desk research',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Mystery shopping',
  cx_solution_12: 'CX consulting',
  cx_solution_13: 'Social Media Listening',
  cx_solution_14: 'Quantitative and qualitative research',
  cx_solution_15: 'Service design',
  cx_solution_16: 'Marketing strategy',
  cx_solution_17: 'UX/UI testing',
  cx_solution_18: 'Audience Insights',
  cx_solution_19: 'We are honored to contribute to your success',
  cx_solution_20:
    "We customize and combine tools and services based on your business's purpose and objectives to deliver tailored results",
  cx_solution_21: 'Comprehensive research',
  cx_solution_22:
    'Empower your decision-making, providing a competitive edge in a data-centric market.',
  cx_solution_23:
    'Qualitative research is a collecting and analyzing non-numerical data to understand concepts, opinions, or experiences',
  cx_solution_24:
    'Quantitative research methods is an objective measurement and numerical analysis of data collected through polls, questionnaires, and surveys',
  cx_solution_25:
    'We utilize a distinct set of tools based on your specific requirements',
  cx_solution_26: 'Tools',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    "Computer-Assisted Web Interviewing is a vital tool in our arsenal, allowing us to conduct surveys and collect data through online platforms with the assistance of specialized software, ensuring efficient and accurate data collection for our clients' market research needs",
  cx_solution_29: 'CATI',
  cx_solution_30:
    'Computer-Assisted Telephone Interviewing is a method we utilize to conduct surveys and gather valuable data over the phone, employing specialized software to streamline the process and ensure accuracy in our market research endeavors',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'Face-to-Face is integral to our approach, facilitating direct interactions and personalized engagements with clients and customers, thereby fostering trust, understanding, and effective communication essential for building lasting relationships and driving business success',
  cx_solution_33: 'Desk research',
  cx_solution_34:
    'Desk research involves comprehensive data collection and analysis, serving as a foundational step in our research process to gather insights and inform strategic decision-making for our clients',
  cx_solution_35: 'SWOT-analysis',
  cx_solution_36:
    'SWOT analysis is a strategic assessment tool that helps our clients identify internal Strengths and Weaknesses, as well as external Opportunities and Threats, providing valuable insights to inform decision-making and develop effective business strategies',
  cx_solution_37: 'Ethnographic research',
  cx_solution_38:
    'Studying people and cultures through immersion and observation in their natural environments, often leading to deep insights into behaviors, beliefs, and social dynamics',
  cx_solution_39: 'In-depth interviews',
  cx_solution_40:
    "In-depth interviews are a qualitative research method we employ, involving thorough, one-on-one discussions with participants to delve deep into their perspectives, experiences, and opinions, providing rich insights and understanding for our clients' research objectives and decision-making processes",
  cx_solution_41: 'Ideation sessions',
  cx_solution_42:
    "Ideation sessions are collaborative brainstorming sessions we facilitate, bringing together diverse perspectives and expertise to generate creative ideas and solutions for our clients' challenges or opportunities, fostering innovation and driving strategic initiatives forward",
  cx_solution_43: 'Buyer personas',
  cx_solution_44:
    "Buyer personas are detailed profiles we develop, representing our clients' target customers based on research and data analysis, enabling us to better understand their needs, preferences, and behaviors, and tailor our strategies and communications to effectively engage and resonate with them, ultimately driving business success",
  cx_solution_45: 'Mystery Shopping',
  cx_solution_46:
    'Mystery Shopping is a strategic service we offer, measuring and monitoring the interactions between a customer and a company during a pre-defined scenario',
  cx_solution_47: 'Accompany checking',
  cx_solution_48:
    'Accompany checking is a specialized form of mystery shopping where our assessors accompany customers throughout their entire service experience, providing comprehensive insights into every aspect of the customer journey, enabling our clients to gain a deep understanding of their service delivery and make informed improvements to enhance overall customer satisfaction and loyalty',
  cx_solution_49: 'Feedback collecting analysis',
  cx_solution_50:
    'Feedback collecting analysis is a critical process we undertake, involving the systematic gathering and analysis of customer feedback from various channels such as surveys, reviews, and social media, enabling us to extract valuable insights, identify trends, and make data-driven recommendations for our clients to improve their products, services, and overall customer experience',
  cx_solution_51: 'Focus groups',
  cx_solution_52:
    "Focus groups are dynamic research sessions we facilitate, bringing together a diverse group of participants to discuss specific topics or products in-depth, allowing us to gather qualitative insights, uncover attitudes, preferences, and perceptions, and explore ideas and concepts, ultimately informing our clients' decision-making and strategy development processes",
  cx_solution_53: '20 years of crafting exceptional customer experiences',
  cx_solution_54:
    'Ensure your message captivates and resonates to your audience.',
  cx_solution_55:
    'We are dedicated to delivering results that exceed your expectations',
  cx_solution_56:
    'We discover complex solutions that contribute to your growth',
  cx_solution_57: 'Elevate your customer experience with our methodology ',
  cx_solution_58:
    'Customer Journey Mapping is about defining a target audience, understanding their needs and environment, and then creating a plan to deliver an engaging experience',
  cx_solution_59: 'BHT',
  cx_solution_60:
    'Brand Health Tracking research can measure how your brand is performing on awareness and usage, brand positioning, and brand performance',
  cx_solution_61: 'CSI',
  cx_solution_62:
    "The Customer Satisfaction Index is a score that indicates how satisfied your customers are with the company's products / services.",
  cx_solution_63:
    "Price monitoring is the process of collection, processing, and analysis of the competitors' prices",
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Net Promoter Score is the gold standard of customer experience metrics, based on one question: How likely would you recommend Organization/Product/Service?',
  cx_solution_66: 'HR research',
  cx_solution_67:
    'Human Resource Research is used to evaluate HR practices and performance and offers detail analysis on the current development and management',
  cx_solution_68:
    'Usability testing is how real people interact with a website, app, or other product and observing their behavior and reactions to it',
  cx_solution_69: 'Other',
  cx_solution_70: 'Experts select the best methodology for your business',
  cx_solution_71: 'Let`s get started',
  cx_solution_72: '1 - KICK OFF MEETING',
  cx_solution_73:
    'During the kickoff meeting, we identify and focus on key tasks. Result: understanding the goal, research tasks, deadlines, methods, and areas of responsibility.',
  cx_solution_74: '2 - CONCEPT DEVELOPMENT',
  cx_solution_75:
    'The 4Service analytics department aggregates data, coordinates the report structure. We then present the results to the client, supplemented with insights and recommendations for implementation.',
  cx_solution_76: '3 - FIELDWORK',
  cx_solution_77:
    'To conduct the research, we recruit participants, conduct interviews, and other basic research stages. The client receives transcribed audio-video interviews, as well as concise summaries.',
  cx_solution_78: '4 - ANALYTICS',
  cx_solution_79:
    'The 4Service analytics department aggregates data, coordinates the report structure. We then present the results to the client, supplemented with insights and recommendations for implementation.',
  cx_solution_80: '5 - WORKSHOP',
  cx_solution_81:
    'Conducting a discussion with the customer regarding the implementation plan',
  cx_solution_82: 'Learn more about our best practices',
  cx_solution_83:
    'The Customer Journey Map enables a quick examination of all communication channels, identification of potential shortcomings at crucial touchpoints, and a comprehensive understanding of areas that may deviate from the planned course.',
  cx_solution_84: 'When to use it?',
  cx_solution_85:
    'Build a personalized advertising campaign based on real data about audience groups',
  cx_solution_86:
    'Find out the triggers of different target audience segments and satisfy their needs',
  cx_solution_87: 'Precisely target each group and get maximum conversion',
  cx_solution_88:
    'The Accompany Checking technique allows to evaluate the possibilities of optimal ways to achieve your goals.',
  cx_solution_89: 'Testing a new website or product before launch',
  cx_solution_90: 'Checking developed processes before implementing changes',
  cx_solution_91: 'Assessing the website or product amid declining sales',
  cx_solution_92: 'Launching a new product tailored to customer preferences',
  cx_solution_93:
    'Studying competing companies to securing a leading position in the market',
  cx_solution_94:
    'We offer a comprehensive 360° view approach to creating a customer-oriented service model.',
  cx_solution_95: 'Staff training and development',
  cx_solution_96: 'Impoving service standarts',
  cx_solution_97: 'Increasing in customer flow and sales',
  cx_solution_98: 'Setting KPI`s and motivation system for the staff',
  cx_solution_99: 'Discover our success stories',
  cx_solution_100: 'Automotive',
  cx_solution_101: 'Tobacco',
  cx_solution_102: 'Retail',
  cx_solution_103: 'Qualitative phase',
  cx_solution_104: 'Quantitative phase (segmentation)',
  cx_solution_105: 'Focus groups',
  cx_solution_106: 'Online worldwide audience coverage',
  cx_solution_107: 'Mix of techniques during interviews',
  cx_solution_108: 'Open questions only',
  cx_solution_109: 'Respondent support 24/7',
  cx_solution_110:
    'Preservation of important details and a real picture of the path',
  cx_solution_111: 'Market research',
  cx_solution_112: 'Diagnostics of the company and competitors',
  cx_solution_113: 'Updated service models and new business processes',
  cx_solution_114: 'Promotion of client-oriented service',
  cx_solution_115:
    'Implementation of changes and control over the development of service strategies',
  usage_attitude_1: 'Usage and \n Attitude (U&A)',
  usage_attitude_2:
    'Gain comprehensive insights into how the target audience \n perceives, utilizes, and engages with your brand.',
  usage_attitude_3: 'Speak to an expert',
  usage_attitude_4: 'About',
  usage_attitude_5:
    '<b>Explore consumer</b> habits to uncover preferences and influences in-depth',
  usage_attitude_6:
    "<b>Discover the 'why'</b> behind consumer choices with our detailed analysis",
  usage_attitude_7:
    '<b>Understand how</b> customers interact with your products for strategic insights',
  usage_attitude_8:
    '<b>Gain insights into</b> consumer behavior, habits and preferences for informed decisions',
  usage_attitude_9: 'Experience',
  usage_attitude_10:
    '4Service experts with over 20 years of customer experience create unique solutions that help grow your business',
  usage_attitude_11: 'Contact us',
  usage_attitude_12: 'For whom',
  usage_attitude_13: 'Who benefits from Brand Usage and Attitude?',
  usage_attitude_14:
    'Various industries and service sectors are interested in:',
  usage_attitude_15: '<b>Recognizing</b> customer demands and pain points',
  usage_attitude_16:
    '<b>Identifying</b> opportunities to boost customer consumption',
  usage_attitude_17:
    '<b>Developing</b> new product categories or marketing strategies',
  usage_attitude_18:
    '<b>Gaining</b> a profound understanding of the competitive landscape',
  usage_attitude_19:
    'The methodology is individually selected based on the client’s request',
  usage_attitude_20:
    '<b>Qualitative research</b> involves gathering and analyzing non-numerical data to grasp concepts, opinions, or experiences.',
  usage_attitude_21:
    '<b>Quantitative research</b> methods entail objectively measuring and numerically analyzing data obtained through polls, questionnaires, and surveys.',
  usage_attitude_22:
    '<b>After collecting data</b>, experts analyze the information and draw conclusions to provide you with the best recommendations.',
  usage_attitude_23: 'Who needs',
  usage_attitude_24: 'Your Success Story Begins Here',
  usage_attitude_25:
    "<b>You'll receive a research</b> with valuable insights that empower strategic decision-making, ensuring actions are based on a deep understanding of consumer behavior.",
  usage_attitude_26:
    '<b>The research provides a solid foundation for refining</b> and optimizing brand strategies, aligning them more closely with consumer preferences and attitudes.',
  usage_attitude_27: 'Solutions',
  usage_attitude_28: 'Explore numerous opportunities with our solutions',
  usage_attitude_29: 'Product preference within category',
  usage_attitude_30:
    "Identify consumers' top preferences across various categories, including food, beverage, clothing, electronics, and more.",
  usage_attitude_31: 'Consumption / usage behavior',
  usage_attitude_32:
    'Analyze the frequency of consumption or usage of specific products/services, and identify the occasions and moments associated with their use.',
  usage_attitude_33: 'Purchase behavior',
  usage_attitude_34:
    'Examine the channels customers utilize for their purchases and identify the sources of information they rely on.',
  usage_attitude_35: 'Consumer motivations and needs',
  usage_attitude_36:
    'Examine the motivations and barriers influencing consumption. Assess the factors impacting consumer purchasing decisions. Explore the perception of the product category, and analyze how price is perceived.',
  usage_attitude_37: 'Our Approach',
  usage_attitude_38: 'Brand usage and attitude study',
  usage_attitude_39: 'Information',
  usage_attitude_40:
    'Conducting surveys using both quantitative and qualitative methods',
  usage_attitude_41: 'Commence',
  usage_attitude_42:
    'Setting goals and determining methods based on individual requests',
  usage_attitude_43: 'Analysis',
  usage_attitude_44: 'Sophisticated techniques employed by our experts',
  usage_attitude_45:
    'We utilize a distinct set of tools based on your specific requirements',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'Computer-Assisted Telephone Interviewing is a method we utilize to conduct surveys and gather valuable data over the phone, employing specialized software to streamline the process and ensure accuracy in our market research endeavors.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    "Computer-Assisted Web Interviewing is a vital tool in our arsenal, allowing us to conduct surveys and collect data through online platforms with the assistance of specialized software, ensuring efficient and accurate data collection for our clients' market research needs.",
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    'Computer-Assisted Personal Interviewing is a method we use to optimize data collection processes, make interviews smoother and ensure accurate insightful results for your business needs.',
  usage_attitude_52: 'Focus groups',
  usage_attitude_53:
    "Focus groups are dynamic research sessions we facilitate, bringing together a diverse group of participants to discuss specific topics or products in-depth, allowing us to gather qualitative insights, uncover attitudes, preferences, and perceptions, and explore ideas and concepts, ultimately informing our clients' decision-making and strategy development processes.",
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Face-to-Face is integral to our approach, facilitating direct interactions and personalized engagements with clients and customers, thereby fostering trust, understanding, and effective communication essential for building lasting relationships and driving business success focus groups.',
  usage_attitude_56: 'Ethnographic Research',
  usage_attitude_57:
    'Studying people and cultures through immersion and observation in their natural environments, often leading to deep insights into behaviors, beliefs, and social dynamics.',
  thank_you_page: 'Thank you for your time! Our manager will contact you soon.',
  back_main: 'Back to main',
  opportunities_1: 'Learn more about our cases',
  opportunities_2: 'Discover',
  opportunities_3: 'for yourself',
  opportunities_4: 'opportunities',
  opportunities_5:
    'of international business together with the research holding 4Service',
  opportunities_6:
    'What <span>products</span> can we strengthen your business with?',
  opportunities_7: 'CX',
  opportunities_8: 'Comprehensive customized solutions for business',
  opportunities_9: 'All types of Mystery Shopping',
  opportunities_10:
    'We implement mystery employee, mystery caller, luxury mystery shopper, automotive mystery shopper, and other types',
  opportunities_11: 'Customized IT solutions for business',
  opportunities_12:
    'Own IT solutions with AI, platforms for feedback analytics and call transcription',
  opportunities_13: 'Market research',
  opportunities_14:
    'For any business requests (Brand Health Tracking, Segmentation, NPS, eNPS, CJM, Product and advertising testing, etc.)',
  opportunities_15: 'Audits',
  opportunities_16:
    'We carry out various service audits, as well as price monitoring (offline, for specific niches, etc.)',
  opportunities_17: 'Service Design',
  opportunities_18:
    '4Service - certified partners of DesignThinkers Academy, providing corporate training for teams and regularly conducting workshops for businesses',
  opportunities_19:
    'We share our experience, but <span>respect and do not disrupt</span> your business process.',
  opportunities_20:
    'We know the difficulties that businesses face in their development journey, and we know how to overcome these barriers thanks to international successful experience. You get not only experience but also resources.',
  opportunities_21: 'We invite you to cooperate!',
  opportunities_22:
    'We offer a <span>partnership program</span> for your business',
  opportunities_23:
    'Under the internationally recognized brand of 4Service, you can positively influence attracting new customers.',
  opportunities_24:
    'Our partners receive comprehensive support, including training, marketing materials, and operational assistance.',
  opportunities_25:
    'Partnership with us will realize your plans without disrupting your current business process.',
  opportunities_26: 'Our <span>offices</span>',
  opportunities_27: 'Our clients',
  opportunities_28: 'We have over 20 years of experience in CX',
  opportunities_29: 'Countries covered',
  opportunities_30: 'thousand',
  opportunities_31: 'Unique projects',
  opportunities_32: 'million',
  opportunities_33: 'Visits by mystery shoppers',
  opportunities_34: 'is included in the TOP 10 European CX agencies',
  opportunities_35: 'Your opportunities with 4Service',
  opportunities_36: 'Increase in profit',
  opportunities_37: 'You will gain access to international orders',
  opportunities_38: 'Unique CX approach',
  opportunities_39: 'Support from experts with 20 years of experience',
  opportunities_40: 'Support',
  opportunities_41: 'Our team will support you to achieve results',
  opportunities_42: 'Sales',
  opportunities_43: 'We will help you set up a sales system',
  opportunities_44: 'Access to software',
  opportunities_45: 'You will get access to all certified IT tools',
  opportunities_46: 'Training',
  opportunities_47:
    'We will not only train your staff but also continue to provide consulting services',
  opportunities_48: 'Who is suitable for partnership?',
  opportunities_49: 'Is your business related to marketing research?',
  opportunities_50: 'Or are you planning to try yourself in a new field',
  opportunities_51: 'Are you planning to:',
  opportunities_52: 'Implement innovations',
  opportunities_53: 'Work with international brands',
  opportunities_54: 'Expand your product portfolio',
  opportunities_55: 'Strengthen your market position?',
  opportunities_56: 'Train your employees',
  opportunities_57: 'Scale your business',
  opportunities_58: 'Our resources = Your resources',
  opportunities_59:
    'Given our extensive experience, we are ready to share it with you at the first stage of cooperation',
  opportunities_60: 'Data analysis:',
  opportunities_61: 'International analytics team',
  opportunities_62: 'Data processing methods - SPSS, Power BI',
  opportunities_63:
    'Innovative methodologies and a wide range of methodologies',
  opportunities_64: 'Reporting in any format',
  opportunities_65: 'Software:',
  opportunities_66: 'Own customized IT developments',
  opportunities_67: 'DigSee Sure for CAPI',
  opportunities_68: 'SurveyMonkey for CAWI',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (call center)',
  opportunities_71: 'Marketing support:',
  opportunities_72: 'PR support',
  opportunities_73: 'Support in promotion and content creation',
  opportunities_74: 'Setting up lead generation processes',
  opportunities_75: 'IT support:',
  opportunities_76:
    'Technical support with installation, configuration, and use of our software.',
  opportunities_77: 'Remote assistance',
  opportunities_78: 'Security control',
  opportunities_79: 'Operational support:',
  opportunities_80: 'Support in setting up field processes',
  opportunities_81: 'Anti-fraud system',
  opportunities_82: 'Recruiting of any complexity',
  opportunities_83:
    'Operational management of the project and field departments team',
  opportunities_84: 'Quality control of recruiting',
  opportunities_85: 'UpWork',
  opportunities_86: 'What bonuses will you get?',
  opportunities_87: 'Increase in customer base',
  opportunities_88: 'Strengthening market image',
  opportunities_89: 'Diversification of customer loss risks',
  opportunities_90: 'Optimization of technological processes',
  opportunities_91: 'International experience',
  opportunities_92: 'More sales to current customers',
  main_67:
    'Competitive price analysis optimizes offerings, boosts profits, and enhances market share by leveraging consumer willingness-to-pay data to identify key features',
  main_68:
    'Ensures your retail outlets align with brand standards, evaluating physical attributes and their impact on customer perception',
  main_69: 'CX solution',
  main_70:
    'Discover our unique 360-degree CX solution: we customize tools and services based on your objectives for driving your business',
  main_71: 'Speech analytics',
  main_72:
    'Our advanced speech analytics tool transcribes spoken language into text, providing invaluable insights for businesses to enhance customer experiences, monitor performance, ensure compliance, and drive growth',
  parthership: 'Partnership',
  banner_1: 'CX consulting and services a 360° view of your business',
  banner_2: 'Stay up to date with the latest research!',
  banner_3:
    'I give my consent to the processing of personal data according to the Privacy Policy',
  banner_4: '*Select the market',
  banner_5: 'Thank you for subscribing!',
  banner_6: "We'll only share the most interesting content with you!",
  banner_7: 'Submit',
  banner_8: 'Ukrainian',
  banner_9: 'Kazakhstan',
  brand_awareness_1: 'Brand Awareness',
  brand_awareness_2: 'and Usage',
  brand_awareness_3:
    'Unlock the potential of your brand awareness and usage for business success',
  brand_awareness_4: 'Speak to an expert',
  brand_awareness_5: 'About',
  brand_awareness_6:
    '<b>Assess the degree of</b> recognition and familiarity of your brand',
  brand_awareness_7:
    '<b>Determine the level of brand </b> awareness in your target market',
  brand_awareness_8: '<b>Keep a close watch</b> on retention and loyalty rates',
  brand_awareness_9:
    '<b>Select the optimal strategy</b> to improve necessary indicators',
  brand_awareness_10: 'Explore numerous opportunities with our solutions',
  brand_awareness_11: 'Brand Awareness',
  brand_awareness_12: 'Brand Usage',
  brand_awareness_13:
    '<b>Discover</b> your brand recognition among consumer segments',
  brand_awareness_14:
    '<b>Monitor</b> the level of awareness among your audience',
  brand_awareness_15:
    '<b>Study</b> the spontaneous thoughts of consumers about to make a purchase',
  brand_awareness_16:
    '<b>Understand</b> the primary sources contributing to your brand awareness',
  brand_awareness_17:
    "<b>Consider</b> the number of consumers experienced your brand's products or services",
  brand_awareness_18:
    '<b>Identify</b> the consumers who stuck with your brand after trying it initially',
  brand_awareness_19:
    '<b>Unlock</b> the probability for your brand to be recommended',
  brand_awareness_20:
    '<b>Uncover</b> the percentage of consumers who consider your brand for future use',
  brand_awareness_21: 'Who benefits from Brand Awareness and Usage?',
  brand_awareness_22: 'Businesses launching a new product',
  brand_awareness_23: 'Businesses revitalizing an established brand',
  brand_awareness_24:
    'Any business seeking to understand and enhance how their brand is recognized and used by consumers',
  brand_awareness_25:
    'Our methodologies provide valuable insights to optimize your brand awareness and usage',
  brand_awareness_26:
    'The methodology is individually selected based on the client’s request',
  brand_awareness_27: 'Brand usage funnel methodology',
  brand_awareness_28:
    'Trial rate outlines the proportion of the audience that is aware of the brand and has tried its products or services.',
  brand_awareness_29:
    'The repeat rate indicates the percentage of the audience familiar with the brand and having tried its products or services who continued to make purchases or use the offerings in the past 12 months.',
  brand_awareness_30:
    "The retention rate reveals the proportion of individuals who consistently used the brand's products or services, indicating the percentage that engaged with these offerings in the past 6 months.",
  brand_awareness_31:
    "The retention rate illustrates the percentage of customers who, having used the brand's products/services in the past 6 months, continue to choose and demonstrate loyalty to the brand over time.",
  brand_awareness_32: 'Brandawareness methodology',
  brand_awareness_33:
    'Top of mind (TOM) represents the first brand spontaneously mentioned by the respondent.',
  brand_awareness_34:
    'Total spontaneous awareness represents the total of spontaneous mentions, including TOM.',
  brand_awareness_35:
    'Aided Awareness reveals how well your brand is recognized by users when prompted.',
  brand_awareness_36: 'Brand attribute performance map',
  brand_awareness_37:
    "This tool assesses and visually represents the importance of different brand attributes, providing insights into how each contributes to your brand's overall performance and consumer perception.",
  brand_awareness_38:
    "Uncover the keys to enhancing your brand's impact with our comprehensive analysis.",
  brand_awareness_39: 'Brands perceptual map',
  brand_awareness_40:
    'This tool offers a visual representation of consumer perceptions in a competitive market landscape. This approach is crucial for clearly delineating differences among brands, emphasizing pivotal market attributes.',
  brand_awareness_41:
    'It streamlines the observation and interpretation of relationships between brands and these defining characteristics.',
  brand_awareness_42:
    'The proximity of a specific brand to certain attributes signifies its strong association with those characteristics. Similarly, the closeness between two brands indicates their similarities, showcasing shared images and, consequently, an affiliation within the same market segment.',
  consumer_journey_map_1: 'Consumer Journey Map',
  consumer_journey_map_2:
    'Discover and overcome barriers in your business with our Consumer Journey Map',
  consumer_journey_map_3: 'TRUSTED BY',
  consumer_journey_map_4: '4Service',
  consumer_journey_map_5:
    'Unlock the potential of CJM to optimize interactions between your consumers and your brand',
  consumer_journey_map_6:
    'Determine what steps the consumer makes before buying a product',
  consumer_journey_map_7:
    'Gain insights from your real customers, not imaginary ones',
  consumer_journey_map_8:
    'Gain a comprehensive understanding of your customers experiences',
  consumer_journey_map_9:
    'Identify missed opportunities and benchmark against your competitors',
  consumer_journey_map_10: 'Discover our CJM approach',
  consumer_journey_map_11:
    'We create a path of consumer interaction with a product, service, company, or brand through various channels and during a certain period of time.',
  consumer_journey_map_12:
    'We outline the stages your consumers navigate before completing a purchase, the expectations, emotions, and motivations of the customer at each stage.',
  consumer_journey_map_13: 'Benefit with us',
  consumer_journey_map_14:
    'We provide a team of seasoned professionals who understand the intricacies of global business success',
  consumer_journey_map_15:
    'We are dedicated to delivering results that exceed your expectations',
  consumer_journey_map_16:
    'We discover complex solutions that contribute to your growth',
  consumer_journey_map_17:
    'We specialize in providing customized CJM solutions',
  consumer_journey_map_18: 'Book a consultation',
  consumer_journey_map_19: 'Data-driven decisions',
  consumer_journey_map_20:
    'Base your decisions on real data rather than assumptions',
  consumer_journey_map_21: 'Optimize your CJM',
  consumer_journey_map_22:
    'Build stronger relationships with customers by addressing their needs and expectations at every stage of their journey',
  consumer_journey_map_23: 'Lead the way',
  consumer_journey_map_24:
    'Compare yourself to your competitors and stay ahead of the market',
  consumer_journey_map_25: 'Recommendations',
  consumer_journey_map_26:
    'Implement our CJM recommendations to unlock success, foster customer loyalty, drive sales, and maximize your business potential',
  consumer_journey_map_27:
    'Discover the real consumer journey with our Consumer Journey Map',
  consumer_journey_map_28:
    'Experts with 20+ years of experience provide a comprehensive research',
  consumer_journey_map_29:
    'Qualitative research involves gathering and analyzing non-numerical data to grasp concepts, opinions, or experiences.',
  consumer_journey_map_30:
    'Quantitative research methods entail objectively measuring and numerically analyzing data obtained through polls, questionnaires, and surveys.',
  consumer_journey_map_31:
    'After collecting data, experts analyze the information and draw conclusions to provide you with the best recommendations.',
  boarding_1: 'Customer journey map',
  boarding_2:
    'Customer Journey Mapping (CJM) is about defining a target audience, understanding their needs and environment, and then creating a plan to deliver an engaging experience',
  boarding_3: 'Net Promotion Score',
  boarding_4:
    'Net Promoter Score (NPS) is the gold standard of customer experience metrics, based on one question: How likely would you recommend Organization/Product/Service?',
  boarding_5: 'Brand Health Tracking',
  boarding_6:
    'Brand Health Tracking (BHT) research can measure how your brand is performing on awareness and usage, brand positioning, and brand performance',
  boarding_7: 'Human Resources Research',
  boarding_8:
    'Human Resource Research is used to evaluate HR practices and performance and offers detail analysis on the current development and management',
  boarding_9: 'UX testing',
  boarding_10:
    'Usability (UX) testing is how real people interact with a website, app, or other product and observing their behavior and reactions to it',
  boarding_11: 'Customer satisfaction index',
  boarding_12:
    'Customer satisfaction measures the extent to which customer’s expectations have been satisfied regarding the purchase of a product or service provided by your company',
  boarding_13: 'Ad testing',
  boarding_14:
    'An advertising campaign is a huge investment. Make sure that your ads are powerful and deliver maximum ROI',
  boarding_15: 'Field audit/Retail audit',
  boarding_16:
    'Collect detailed data on your store or brand, analyze growth barriers, and create effective strategies to overcome them',
  boarding_17: 'Play4Sales',
  boarding_18:
    'Performance Management Software. Gamified application for increasing sales',
  boarding_19: 'Voicer',
  boarding_20:
    'Feedback Management System. Voice of your customer in one system. Customer Feedback Management System',
  boarding_21: 'Qualitative research',
  boarding_22:
    'Qualitative research is collecting and analyzing non-numerical data to understand concepts, opinions, or experiences',
  boarding_23: 'Quantitative research',
  boarding_24:
    'Quantitative research methods is an objective measurement and numerical analysis of data collected through polls, questionnaires, and surveys',
  boarding_25: 'Mystery Shopping',
  boarding_26:
    'Mystery shopping is a market research technique for measuring, and monitoring the interactions between a customer and a company or organisation during a pre-defined scenario',
  boarding_27: 'Wantent',
  boarding_28:
    'An AI-powered content intelligence platform providing insights from the audience',
  boarding_29: 'Price monitoring ',
  boarding_30:
    "Price monitoring is the process of collection, processing, and analysis of the competitors' prices",
  boarding_31: 'Service design',
  boarding_32:
    'Service design is how to understand the needs and desires of the people who will use a product or service by spending time with them',
  boarding_33: 'Automotive Industry Price Analysis',
  boarding_34:
    'Competitive price analysis optimizes offerings, boosts profits, and enhances market share by leveraging consumer willingness-to-pay data to identify key features',
  boarding_35: 'Facility Check Audit',
  boarding_36:
    'Ensures your retail outlets align with brand standards, evaluating physical attributes and their impact on customer perception',
  boarding_37: 'CX solution',
  boarding_38:
    'Discover our unique 360-degree CX solution: we customize tools and services based on your objectives for driving your business',
  boarding_39: 'Speech analytics',
  boarding_40:
    'Our advanced speech analytics tool transcribes spoken language into text, providing invaluable insights for businesses to enhance customer experiences, monitor performance, ensure compliance, and drive growth',
  boarding_41: 'Usage and Attitude',
  boarding_42:
    'Usage and Attitude is our approach to understanding how audiences engage with your brand by analyzing practical usage patterns and psychological attitudes, enabling tailored marketing and product strategies',
  boarding_43: 'Brand Awareness & Usage',
  boarding_44:
    'Brand Awareness and Usage involves evaluating recognition, familiarity, interaction frequency, and retention within your target market to enhance brand visibility and engagement',
  boarding_45: 'Brand Identity & Positioning',
  boarding_46:
    'Brand Identity and positioning is a comprehensive analysis uncovering consumer perceptions, diverse audience views, and insights into how your brand is perceived',
  boarding_47: 'Customer Profiling & Segmentation',
  boarding_48:
    'Customer Profiling and Segmentation involves dividing your target market into distinct groups based on shared characteristics, enabling the creation of personalized strategies that resonate with specific audience segments',
  boarding_49: 'Consumer Journey Map',
  boarding_50:
    'A Consumer Journey Map is a visual representation of the stages, touchpoints, emotions, and motivations experienced by a consumer throughout their interaction with your product or brand',
  boarding_51:
    'Learn about innovative techniques, that underpin our commitment to provide actionable insights and driving impactful results for your business',
  boarding_52:
    'Explore our suite of products with wealth of data-driven intelligence tailored to your industry, enabling you to make informed decisions and stay ahead of the curve',
  boarding_53:
    'Dive deep into market trends, consumer behavior, and competitive landscapes with our comprehensive market research solutions',
  boarding_54: 'Ad Hoc Research',
  boarding_55:
    'Ad Hoc Research is a customized approach designed to address a specific issue, using a mix of existing methodologies or a new approach to create a tailored solution.',
  boarding_56: 'Sociological Research',
  boarding_57:
    'Sociological Research uncovers the patterns of human behavior and social interactions, providing valuable insights to drive innovative strategies and create meaningful connections with target audiences.',
  not_found_1: 'Oops! We can’t find that page.',
  not_found_2: 'Why not check out our products?',
  not_found_3: 'Book consultancy',
  customer_profiling_segmentation_1: 'Customer Profiling and Segmentation',
  customer_profiling_segmentation_2:
    "We'll help you pinpoint your target audience, making your strategy more effective",
  customer_profiling_segmentation_3:
    'Utilize the power of market segmentation to ensure that your messages always reach your target consumer groups effectively',
  customer_profiling_segmentation_4: 'Understand consumers deeper',
  customer_profiling_segmentation_5:
    'Find the best target audience for your product',
  customer_profiling_segmentation_6: 'Explore new segments to attract',
  customer_profiling_segmentation_7: 'Discover barriers for purchase',
  customer_profiling_segmentation_8:
    'We specialize in delivering tailored segmentation solutions',
  customer_profiling_segmentation_9: 'Unique approach',
  customer_profiling_segmentation_10:
    'Experience a unique, custom-tailored segmentation solution crafted specifically to your needs',
  customer_profiling_segmentation_11: 'Target Personas',
  customer_profiling_segmentation_12:
    'Crafted Target Personas based on deep market analysis and comprehensive research',
  customer_profiling_segmentation_13: 'New segments',
  customer_profiling_segmentation_14:
    'Identify your most valuable audience segments and unlock the benefits of tailored targeting',
  customer_profiling_segmentation_15: 'Recommedations',
  customer_profiling_segmentation_16:
    'Implement our recommendations and unlock success, foster customer loyalty, boost sales, and maximize your business potential',
  customer_profiling_segmentation_17:
    'Experts with 20+ years of experience provide a comprehensive research',
  customer_profiling_segmentation_18:
    'Qualitative research involves gathering and analyzing non-numerical data to grasp concepts, opinions, or experiences.',
  customer_profiling_segmentation_19:
    'Quantitative research methods entail objectively measuring and numerically analyzing data obtained through polls, questionnaires, and surveys.',
  customer_profiling_segmentation_20:
    'After collecting data, experts analyze the information and draw conclusions to provide you with the best recommendations.',
  customer_profiling_segmentation_21:
    "We don't just take measurements, we also give you an action plan with clear step-by-step actions",
  customer_profiling_segmentation_22: 'Our methodology',
  customer_profiling_segmentation_23: 'Jaccard Index',
  customer_profiling_segmentation_24:
    'When respondents are asked directly, their answers about company preferences may not align with their actual behavior. Instead of direct questioning, we utilize statistical Jaccard analysis to understand the relationship between company attributes and consumer choice.',
  customer_profiling_segmentation_25: 'Drivers',
  customer_profiling_segmentation_26:
    'We identify drivers (factors influencing a certain outcome) and categorize them into key, medium, and unimportant drivers based on their significance.',
  customer_profiling_segmentation_27: 'Segmentation',
  customer_profiling_segmentation_28:
    'You get segmentation with clear characteristics of customer choices.',
  customer_profiling_segmentation_29: 'Our approach to segmentation',
  customer_profiling_segmentation_30: 'Demographic',
  customer_profiling_segmentation_31: 'age',
  customer_profiling_segmentation_32: 'gender',
  customer_profiling_segmentation_33: 'income',
  customer_profiling_segmentation_34:
    'It helps tailor products, services, and marketing to specific groups, improving relevance and effectiveness',
  customer_profiling_segmentation_35: 'Psychographic',
  customer_profiling_segmentation_36: 'lifestyle',
  customer_profiling_segmentation_37: 'values',
  customer_profiling_segmentation_38: 'behaviors',
  customer_profiling_segmentation_39:
    'It enables personalized marketing, deeper connections with customers, and increased loyalty and sales for the company',
  customer_profiling_segmentation_40: 'Behavioral',
  customer_profiling_segmentation_41: 'actions',
  customer_profiling_segmentation_42: 'motivation',
  customer_profiling_segmentation_43: 'purchase frequency',
  customer_profiling_segmentation_44:
    'It allows for targeted marketing, enhanced customer satisfaction, increased retention, and improved profitability for the company',
  customer_profiling_segmentation_45: 'Geographic',
  customer_profiling_segmentation_46: 'location',
  customer_profiling_segmentation_47:
    ' It enables targeted marketing, tailored products, efficient distribution, and increased market penetration for the company.',
  brands_identity_1: 'Brand identity and positioning',
  brands_identity_2:
    'Transform your brand with our in-depth analysis, revealing insights into consumer perceptions and refining your strategy for succes',
  brands_identity_3: 'Speak to an expert',
  brands_identity_4: '<b>Discover a comprehensive</b> brand identity analysis',
  brands_identity_5: '<b>Explore insights</b> gathered on consumer perceptions',
  brands_identity_6: '<b>Understand diverse</b> target audience views',
  brands_identity_7:
    '<b>Gain insights into</b> consumer behavior, habits and preferences for informed decisions',
  brands_identity_8:
    '4Service experts with over 20 years of customer experience create unique solutions that help grow your business',
  brands_identity_9:
    "<b>You'll have access to a research package equipped with all the essential data.</b> It serves as a diagnostic tool, highlighting areas of strength and areas that may require attention",
  brands_identity_10:
    '<b>Our services provide businesses with the necessary tools</b> to make informed decisions, refine strategies, and drive success in their respective industries.',
  brands_identity_11:
    "To enhance your brand's standing in the competitive market",
  brands_identity_12: 'Businesses striving to enhance their market presence',
  brands_identity_13: 'Businesses seeking to elevate their strategy',
  brands_identity_14: 'Brand Identity',
  brands_identity_15: 'Brand Positioning',
  brands_identity_16:
    "<b>Uncover your brand's</b> perception across consumer segments.",
  brands_identity_17:
    '<b>Identify the qualities attributed</b> to your brand and competitors.',
  brands_identity_18:
    '<b>Evaluate strengths</b> and weaknesses for strategic improvement.',
  brands_identity_19:
    '<b>Assess the alignment</b> of your brand image with promoted values.',
  brands_identity_20:
    '<b>Evaluate the perceived</b> trustworthiness of your brand.',
  brands_identity_21:
    '<b>Explore the archetypal</b> and symbolic perception of your brand.',
  brands_identity_22:
    "<b>Identify your brand's</b> unique selling points and differentiators in comparison to competitors.",
  brands_identity_23:
    '<b>Pinpoint the key attributes influencing</b> the likelihood of consumer purchases of your products/services.',
  brands_identity_24:
    '<b>Determine how your brand is currently positioned</b> in the minds of consumers and identify aspects to communicate for attracting desired market segments.',
  brands_identity_25:
    'The methodology is individually selected based on the client’s request',
  brands_identity_26: 'Brand Awareness and Usage',
  brands_identity_27:
    'Evaluating the brand level involves measuring awareness and consumption levels, serving as a fundamental component in the assessment.',
  brands_identity_28: 'Brand Delivery and Trust',
  brands_identity_29:
    "This metric gauges client advocacy (recommendation) and the brand's consistent ability to fulfill promises, create positive customer experiences, and build trust.",
  brands_identity_30: 'Brand Image Performance',
  brands_identity_31:
    "Assessing the brand's performance involves evaluating its association with six crucial values (3 rational and 3 emotional), its distinctiveness within the category, and its ability to instill pride among consumers.",
  brands_identity_32: 'Brand Health Score',
  brands_identity_33:
    'The Brand Health Score shows you a comprehensive understanding of how your brand is performing in the competitive landscape.',
  sociological_research_1: 'Sociological research',
  sociological_research_2:
    'We conduct an in-depth analysis of social phenomena and processes, which helps to make informed decisions with the help of unique analytical conclusions and recommendations',
  sociological_research_3: 'Contact us',
  sociological_research_4: 'Our clients',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    'CATI - is a data collection method that uses a computer to conduct surveys over the phone. CATI software allows you to automate the processes of data collection, response entry and survey management. Using CATI makes it easier to control the survey, allows you to quickly analyze and process the data, and ensures a high level of survey standardization.',
  sociological_research_7: 'CATI',
  sociological_research_8:
    'CAWI - is a method of data collection in which respondents fill out survey questionnaires or answer questions via a web interface, and the data is automatically collected and processed using specialized software. The CAWI method allows you to conduct large-scale surveys, quickly collect and analyze data.',
  sociological_research_9: 'CAPI',
  sociological_research_10:
    'CAPI - is a method of data collection for face-to-face surveys in which the researcher uses a tablet or mobile phone to enter respondents answers to questions, which allows for automated data collection, reducing input errors and simplifying the analysis of results.',
  sociological_research_11: 'Our experts use a variety of tools',
  sociological_research_12: 'Sample',
  sociological_research_13: 'We calculate and apply samples of any complexity',
  sociological_research_14:
    'In our work, we use simple, systematic, stratified, and cluster samples',
  sociological_research_15:
    'For nationally representative face-to-face surveys, we use stratified multistage sampling procedures',
  sociological_research_16:
    'The selection of settlements by regions is carried out in accordance with the size and type of settlement, their number is determined in proportion to their volume in each region',
  sociological_research_17: 'We carry out',
  sociological_research_18: 'Public opinion research',
  sociological_research_19: 'Socio-economic research',
  sociological_research_20: 'Desk research',
  sociological_research_21: 'Assessment of the quality of social services',
  sociological_research_22: 'Case study',
  sociological_research_23: 'Personnel research',
  sociological_research_24:
    'Research on specific audiences (vulnerable groups, including IDPs, military, youth, etc.)',
  sociological_research_25: 'We work with each client individually',
  sociological_research_26: 'Our clients:',
  sociological_research_27: 'Charitable Foundations',
  sociological_research_28: 'Public organizations and unions',
  sociological_research_29: 'State structures',
  sociological_research_30: 'Our approach to work',
  sociological_research_31:
    'We use a scientific approach to our work and a deep understanding of the current reality, as well as various research methods to collect data.',
  sociological_research_32:
    'We engage highly specialized experts with the necessary experience to address a specific request and have a pool of experts in various fields.',
  sociological_research_33:
    'Our in-house analytics department analyzes the collected data using a unique set of tools depending on the specifics of the task.',
  sociological_research_34:
    'We generate reports with detailed conclusions and recommendations, taking into account the needs and expectations of your target audience.',
  sociological_research_35:
    'We provide advice and support during the implementation of the recommendations, helping you to make positive changes.',
  sociological_research_36: 'Our research on TV',
  sociological_research_37: 'Check out our reports',
  sociological_research_38:
    'Our scientific approach to our work guarantees our customers accurate and reliable sociological data throughout Ukraine and Europe with minimal error',
  sociological_research_39:
    'We work in compliance with all principles and standards of ethics and rules for conducting sociological research.',
  sociological_research_40:
    'We adhere to the Code of Professional Ethics for Sociologists of the Sociological Association of Ukraine and the Helsinki Declaration of Ethical Principles for Research. Members of ESOMAR, Ukrainian Marketing Association, All-Ukrainian Association of Contact Centers of Ukraine.',
  sociological_research_41: 'Field',
  sociological_research_42: 'online panel',
  sociological_research_43: 'regular interviewers',
  sociological_research_44: 'supervisors',
  sociological_research_45: 'Field quality control',
  sociological_research_46: '100% fixation of GPS coordinates (CAPI)',
  sociological_research_47:
    'Validation of questionnaires (CAWI, CAPI, CATI, PAPI)',
  sociological_research_48: '100% recording of interviews (CAPI, CATI)',
  sociological_research_49: 'We use licensed software:',
  sociological_research_50:
    'SPSS for analyzing statistical data, processing data sets',
  sociological_research_51:
    'DigSee Sure for CAPI: questionnaire logic, geolocation, audio recording of interviews, demonstration of video materials',
  sociological_research_52:
    'SurveyMonkey for CAWI: questionnaire logic, convenient format, visualization',
  sociological_research_53:
    'VoIPTime (contact center): programming of questionnaires of any complexity, transfer of audio recordings to the customer',
  sociological_research_54: 'Sample',
  sociological_research_55: 'We calculate and apply samples of any complexity',
  sociological_research_56:
    'In our work, we use simple, systematic, stratified, and cluster samples',
  sociological_research_57:
    'For nationally representative face-to-face surveys, we use stratified multistage sampling procedures',
  sociological_research_58:
    'The selection of settlements by regions is carried out in accordance with the size and type of settlement, their number is determined in proportion to their volume in each region',
  sociological_research_59: 'Team',
  sociological_research_60:
    'Our sociologists are members of the Association of Sociologists of Ukraine',
  sociological_research_61:
    'We have 12 full-time analysts with experience in both quantitative and qualitative projects in various industries.',
  sociological_research_62:
    'We have a strong analytical team of professionals with over 10 years of research experience. We have scientists, PhDs, MBI.',
  sociological_research_63: 'Iryna Maksymiuk',
  sociological_research_64:
    'Head of the Research Department. More than 15 years of experience in marketing research, research management at all stages, preparation of analytical reports, presentations',
  sociological_research_65: 'Andriy Biloskursky',
  sociological_research_66:
    'Head of Programming and Data Processing. 20 years of experience in marketing, sociological and market research',
  sociological_research_67: 'Olena Somova',
  sociological_research_68:
    'Programming Specialist Postgraduate student in sociology at DonSUU. 5 years of experience in marketing research Member of the Board of the Democratic Alliance of Women, Head of Fundraising.',
  sociological_research_69: 'Anna Padalka',
  sociological_research_70:
    'Head of the Sociological Research Division of the Research Department, has 13 years of experience in sociological and social projects at the international and national levels.  PhD in Sociology, Head of the Research Committee of the Ukrainian Association of Contact Centers of Ukraine.',
  ad_hoc_1: 'Ad Hoc',
  ad_hoc_2: 'Discover our alternative research for unique cases',
  ad_hoc_3: 'Contact us',
  ad_hoc_4: 'Our Approach',
  ad_hoc_5:
    'Our team of experts meticulously analyze the data collected, providing you with discover complex solutions that contribute to your growth.',
  ad_hoc_6:
    'We design a unique research tailored to your specific requirements, drawing from a diverse range of methodologies, including surveys, interviews, focus groups, data analysis, and more.',
  ad_hoc_7: 'Why Choose Ad Hoc Research?',
  ad_hoc_8: 'Flexibility',
  ad_hoc_9:
    'No two research projects are the same. With Ad Hoc Research, we have the flexibility to adapt and evolve the unique approach as needed, ensuring that your insights remain relevant and actionable.',
  ad_hoc_10: 'Precision',
  ad_hoc_11:
    'By tailoring our methodologies to your unique requirements, we deliver precise and targeted results that provide valuable insights.',
  ad_hoc_12: 'Innovation',
  ad_hoc_13:
    'We embrace innovation and creativity in our research process, exploring new techniques and approaches to uncover hidden opportunities and challenges.',
  ad_hoc_14: 'Timeliness',
  ad_hoc_15:
    'Ad Hoc Research allows for swift implementation, enabling you to access critical insights in a timely manner, keeping you ahead of the curve in your industry.',
  ad_hoc_16: 'Our Expertise',
  ad_hoc_17:
    '4Service experts with over 20 years of market research and customer experience create unique solutions that help grow your business',
  ad_hoc_18:
    'We utilize a distinct set of tools based on your specific requirements',
  ad_hoc_19: 'No one methodology work for you?',
  ad_hoc_20: 'Request The Ad Hoc Research',
  ad_hoc_21: 'Tailored for specific issue',
  ad_hoc_22:
    'We use both a mix of different parts of the methodologies as well as a completely new approach',
  ad_hoc_23: 'Individual approach',
  ad_hoc_24: 'We carefully tailor a solution directly to your issue',
  ad_hoc_25: 'Go above and beyond',
  ad_hoc_26:
    'We provide an actionable plan with recommendations to help your business achieve its goals',
  ad_hoc_27: 'You have a non-standard issue',
  ad_hoc_28: 'None of the methodologies work for your issue',
  ad_hoc_29:
    'You would like to request different methodologies or parts of them at once',
  ad_hoc_30: 'Who benefit from Ad Hoc research?',
  service_design_1: 'Some of our clients',
  service_design_2: 'Service Design',
  service_design_3: 'Transform your business from product and sales orientation to service and human orientation',
  service_design_4: 'Speak to an expert',
  service_design_5: 'Learn for free!',
  service_design_6: 'We have collected practical and useful information in our Service Design Library',
  service_design_7: 'The Library contains detailed descriptions of each step, relevant tools and techniques, tips on important details, and video demonstrators.',
  service_design_8: 'Go to the Library',
  service_design_9: 'Welcome to Service Design!',
  service_design_10: 'Service Design aims to create and improve services by addressing the entire service ecosystem — people, processes, and touchpoints to enhance both user satisfaction and operational efficiency',
  service_design_11: "Service design is not only why you prefer one cafe over another. It's also the reason why you come back to this place again and again, and tell all your friends about it.",
  service_design_12: 'How business can benefit with Service Design',
  service_design_13: 'User satisfaction',
  service_design_14: 'Create seamless, personalized experiences that delight users.',
  service_design_15: 'Operational efficiency',
  service_design_16: 'Streamline processes, reduce costs, and improve service delivery.',
  service_design_17: 'Brand consistency',
  service_design_18: 'Ensure all touchpoints align with the brand’s values.',
  service_design_19: 'Adaptability to changes',
  service_design_20: 'Design services that can grow and evolve with changing needs.',
  service_design_21: 'Loyalty increasing',
  service_design_22: 'Build stronger customer relationships and boost loyalty through exceptional service experiences.',
  service_design_23: 'Competitive advantage',
  service_design_24: 'Stand out in the market with unique, memorable service experiences.',
  service_design_25: 'Why',
  service_design_26: 'Why Choose Service Design?',
  service_design_27: 'It is a culture, methodology, and way of thinking that helps companies worldwide shift from being product and sales-oriented to focusing on human needs and problems.',
  service_design_28: 'Our services',
  service_design_29: 'Customer Service Design',
  service_design_30: 'We develop and implement customer-focused projects to create new products or improve existing ones. We also provide training for future independent work with these changes.',
  service_design_31: 'Employee service design',
  service_design_32: 'A practical course to develop and implement employee oriented processes, structures and changes within the company.',
  service_design_33: 'Future design thinking',
  service_design_34: 'Development of futures concepts and company strategy, considering different scenarios and possible innovations.',
  service_design_35: 'Creativity in Business',
  service_design_36: 'A practical training that helps participants use creativity to solve specific problems and develop unique market solutions.',
  service_design_37: 'Our Workshops',
  service_design_38: 'We provide practical workshops with a worldwide-recognized certification for our participants',
  service_design_39: 'Сreate products, services, and facilities that exceed expectations',
  service_design_40: 'Implement business processes that work',
  service_design_41: 'Harmonize the relationship between company employees and customers',
  service_design_42: 'Calendar',
  service_design_43: 'Coming soon',
  service_design_44: 'Learn more about our previous events',
  service_design_45: 'Основи Service Design',
  service_design_46: 'Сreate products, services, and facilities that exceed expectations',
  service_design_47: 'EX Service Design',
  service_design_48: 'Сreate products, services, and facilities that exceed expectations',
  service_design_49: 'More',
  service_design_50: 'Our approach',
  service_design_51: '4Service is a backbone for your human-centered service!',
  service_design_52: 'We help companies develop the necessary mindset and create a human-centered internal environment.',
  service_design_53: 'Tools',
  service_design_54: 'We utilize a distinct set of techniques and tools based on your specific requirements',
  service_design_55: 'User Research',
  service_design_56: 'Interviews, surveys, F2F, ethnographic studies to understand user needs and behaviors',
  service_design_57: 'Personas',
  service_design_58: 'Fictional characters representing different user types to guide design decisions',
  service_design_59: 'Customer Journey Maps',
  service_design_60: 'Visual representations of the user’s experience through the service, identifying key touchpoints and pain points',
  service_design_61: 'Brainstorm sessions',
  service_design_62: 'Collaborative sessions with stakeholders to generate ideas and solutions.',
  service_design_63: 'Prototyping',
  service_design_64: 'Creating preliminary versions of the service to test and refine concepts',
  service_design_65: 'Service Blueprints',
  service_design_66: 'Detailed diagrams showing the relationships between different service components and processes',
  service_design_67: 'Partner',
  service_design_68: '4Service & DTA',
  service_design_69: '4Service is the official exclusive representative of the Design Thinking Academy',
  service_design_70: 'As part of our partnership, we:',
  service_design_71:
  '<b>provide practical training</b> using the service design method at workshops by leading experts;',
  service_design_72:
  '<b>train certified</b> design thinking specialists;',
  service_design_73:
  '<b>implement service design</b> projects with international facilitators for many companies.',
  service_design_74: 'We established Service Design Centers of Excellence in:',
  service_design_75: 'Ukraine',
  service_design_76: 'Kazakhstan',
  service_design_77: 'Turkey',
  service_design_78: 'Georgia',
  service_design_79: 'Azerbaijan',
  service_design_80: 'Find out more about our cases',
  service_design_81: 'Go to the library',
  four_opinion_1: 'Опитування від 4Service',
  four_opinion_2: 'Проходьте онлайн-опитування за гроші та заробляйте не виходячи з дому',
  four_opinion_3: 'Почати',
  four_opinion_4: 'Наші переваги',
  four_opinion_5: 'Нам довіряють',
  four_opinion_6: 'Наша спільнота налічує понад 90 тис споживачів в Україні та 100 тис у Казахстані',
  four_opinion_7: 'Гідно оцінюємо ваш час',
  four_opinion_8: 'Ми цінуємо ваш час, тому за участь в опитуваннях ви маєте можливість отримати подарунок або грошову винагороду',
  four_opinion_9: 'Ваша думка має значний вплив',
  four_opinion_10: 'Кожен ваш коментар допомагає  брендам краще розуміти потреби своїх клієнтів',
  four_opinion_11: 'Проходьте опитування на 4Opinion від 4Service та заробляйте онлайн або виграйте приз',
  four_opinion_12: 'Приєднатися до 4Opinion',
  four_opinion_13: 'Ще більше цікавих історій у нас на каналі',
  four_opinion_14: 'Детальніше для України',
  four_opinion_15: 'Детальніше для Казахстану',
  four_opinion_16: 'Чому обирають нас?',
  four_opinion_17: '4Service — міжнародний дослідницький холдинг з управління клієнтським досвідом',
  four_opinion_18: 'Ми входимо до топ-10 європейських агентств з управління клієнтським досвідом',
  four_opinion_19: 'Наша місія – покращувати обслуговування клієнтів по всьому світу!',
  four_opinion_20: 'Як почати заробляти на онлайн-опитуваннях?',
  four_opinion_21: 'Зареєструйтесь на сайті ',
  four_opinion_22: 'Отримайте повідомлення про актуальні опитування',
  four_opinion_23: 'Проходьте опитування до кінця та заробляйте',
  four_opinion_24: 'Реальні відгуки від наших респондентів',
  four_opinion_25: 'Віталя Кохан',
  four_opinion_26: '“Пройшов опитування, і навіть забув про це. Через деякий час зателефонувала пані Наталья і повідомила що я виграв приз. І ось він мій приз Samsung Galaxy M52 5G.Дуже дякую😁”',
  four_opinion_27: 'Вікторія Поповська',
  four_opinion_28: '“Колонку отримала, дуже дякую за чесний, справедливий, цікавий розіграш.”',
  four_opinion_29: 'Юлія Олександрівна',
  four_opinion_30: '“Напевно найнеймовірніше, що зі мною траплялося за останній час💕 Мало того, що виграла, так ще й справді круту річ. Очікувала всяке, але що настільки круті будуть, навіть і не могла подумати. Ще раз величезне спасибі”',
  four_opinion_31: 'Тільки з нами є можливість заробити “живі” гроші або виграти приз',
  four_opinion_32: 'Відсежуйте актуальні опитування у нас на каналі',
  four_opinion_33: 'Перейти в канал',
  career_1: 'Станьте частиною',
  career_2: '4Service',
  career_3: 'Чекаємо вас у команді з безмежними можливостями постійного професійного зростання!',
  career_4: 'До вакансій',
  career_5: 'Не знайшли вакансію?',
  career_6: 'Відправте нам резюме і ми зв`яжемося з вами!',
  career_7: 'Відправити резюме',
  career_8: 'Форма реєстрації',
  career_9: '*Ім’я',
  career_10: '*Прізвище',
  career_11: '*Email',
  career_12: '*Номер телефону',
  career_13: 'Завантажити резюме',
  career_14: 'Надіслати',
  career_15: 'Розпочніть свою кар`єру у 4Service',
  career_16: 'Основні завдання:',
  career_17: 'Вимоги:',
  career_18: 'Ми пропонуємо:',
  career_19: 'Подати заявку',
  career_20: '4Service — міжнародний дослідницький холдинг',
  career_21: 'Працюємо з 2001 року',
  career_22: 'Про нас',
  career_23: 'Розвиваємо корпоративну культуру ',
  career_24: 'Наші ініціативи',
  career_25: 'Покращуємо обслуговування клієнтів по всьому світу',
  career_26: 'Вакансії 4Service',
  career_27: 'Ваші можливості з 4Service',
  career_28: 'Підтримка ментального здоров`я у рамках щотижневих сесій “Morning coffee”, тімбілдінги та соціальні ініцативи',
  career_29: 'Цікавий досвід роботи з найвідомішими світовими брендами, такими як: <span>Mercedes, Huawei, Philip Morris</span>',
  career_30: 'Постійний професійний розвиток через семінари, тренінги та воркшопи',
  career_31: 'Роль таємного покупця та вплив на розвиток відомих брендів',
  career_32: 'Співпраця з будь-якої точки світу та можливість працювати віддалено або в офісі в: <span>Україні, Казахстані, Румунії, Австрії, Азербайджані, Грузії</span>',
  career_33: 'Розвиток власного бренду через участь в івентах, вебінарах, нетворкінгах',
  career: 'Career',
  footer_btn_1: 'Become a Shopper',
  footer_btn_2: 'Take paid surveys',
};
