import styled from 'styled-components';

export const SearchStyles = styled.div`
  .container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 70px 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;
  }

  .listWrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;

    .listItem {
      max-width: 370px;
      width: 100%;
      flex: 1 0 370px;
      cursor: pointer;

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        flex-shrink: 0;
      }

      p {
        color: #000;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        position: relative;
        z-index: 5;
      }

      .listItemNoImg {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        font-weight: 700;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;

        &::before {
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          background-image: linear-gradient(
              217deg,
              rgba(255, 0, 0, 0.8),
              rgba(255, 0, 0, 0) 50.71%
            ),
            linear-gradient(
              127deg,
              rgba(0, 255, 0, 0.8),
              rgba(0, 255, 0, 0) 70.71%
            ),
            linear-gradient(
              336deg,
              rgba(0, 0, 255, 0.8),
              rgba(0, 0, 255, 0) 70.71%
            );
          background-size: 150vw 150vh;
          animation: 15s infinite granimate;
        }

        @keyframes granimate {
          0%,
          100% {
            background-position: 0 25%;
          }
          25%,
          75% {
            background-position: 50% 50%;
          }
          50% {
            background-position: 100% 100%;
          }
        }
      }
    }
  }

  .paginationWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .currentPage {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }

    .arrowPrev {
      cursor: pointer;
      transform: rotate(180deg);
    }

    .arrowNext {
      cursor: pointer;
    }
  }
`;
