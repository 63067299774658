import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../../state/hooks';
import { SearcherStyles } from './styles';
import { SearchIcon } from '../../assets/icons/searcher/SearchIcon';
import { useNavigate } from 'react-router-dom';
import { ApiSearcher } from '../../api/entities/ApiSearcher';
import { useDebounce } from 'usehooks-ts';
import { CloseIcon } from '../../assets/icons/Close';
import { AdminApi } from '../../api/adminApi';

type TSearcher = {
  colorTheme: 'darkTheme' | 'lightTheme' | 'greenTheme';
}

export const Searcher: FC<TSearcher> = ({ colorTheme }) => {
  const navigate = useNavigate();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [list, setList] = useState<ApiSearcher[]>([]);

  console.log(list);

  const listRef = useRef<HTMLDivElement>(null);

  const searchDebounce = useDebounce(query, 1000);

  const handleClickToItem = (item: ApiSearcher) => {
    setQuery('');
    setOpen(false);
    switch (item.type) {
      case 'blog':
        navigate(`/${interfaceLanguage}/blog/${item.link}`);
        break;
      case 'case':
        if (item.imageURL) {
          navigate(`/${interfaceLanguage}/cases/${item.link}`);
        } else {
          navigate(`/${interfaceLanguage}/${item.link}`);
        }
        break;
      default:
        navigate(`/${interfaceLanguage}/${item.link}`);
        break;
    }
  };

  const handleGoToSearch = () => {
    if (query.length) {
      setQuery('');
      setOpen(false);
      navigate(`/${interfaceLanguage}/search?search=${query}`);
    }
  };

  const getInitials = (input: string): string => input
    .split(/\s+/)
    .map((word) => word.charAt(0))
    .join('');

  useEffect(() => {
    AdminApi.searchPage(interfaceLanguage, searchDebounce, 5).then((res) => {
      if (res.statusCode === 200) {
        if (searchDebounce.length) {
          setList(res.data);
        } else {
          setList([]);
        }
      }
    });
  }, [searchDebounce]);

  useEffect(() => {
    const handleScroll = (event: { deltaY: number; preventDefault: () => void; }) => {
      const el = listRef.current;
      if (!el) return;

      const atTop = el.scrollTop === 0;
      const atBottom = el.scrollTop + el.clientHeight >= el.scrollHeight;

      if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
        event.preventDefault();
      }
    };

    const el = listRef.current;
    if (el) {
      el.addEventListener('wheel', handleScroll, { passive: false });
    }

    return () => {
      if (el) {
        el.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <SearcherStyles>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="searchButton"
      >
        <SearchIcon color={colorTheme === 'lightTheme' ? '#000' : '#fff'} />
      </button>
      <div className={`searchWrapper ${open ? 'active' : ''}`}>
        <div className="inputWrapper">
          <div className="inputContainer">
            <input
              className="searchInput"
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Enter page name"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleGoToSearch();
                }
              }}
            />

            <div className="listWrapper" ref={listRef}>
              {!!list.length && list.map((item) => (
                <div className="searchItem" key={item.link} onClick={() => handleClickToItem(item)}>
                  {item.imageURL
                    ? (
                      <img src={item.imageURL} alt={item.name} />
                    )
                    : (
                      <div className="searchItemNoImg">
                        {getInitials(item.name)}
                      </div>
                    )}
                  <p>{item.name}</p>
                </div>
              ))}
            </div>

            <button
              type="button"
              onClick={() => setOpen(false)}
              className="closeButton"
            >
              <CloseIcon color="#000" height={20} width={20} />
            </button>
          </div>
        </div>
      </div>
    </SearcherStyles>
  );
};
