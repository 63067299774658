import styled from 'styled-components';

export const SearcherStyles = styled.div`
  .searchButton {
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .closeButton {
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: fit-content;
  }

  .searchWrapper {
    position: fixed;
    left: 0;
    top: -200%;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 10;
    background: rgb(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 75px;
    transition: all 0.3s ease-in-out;

    &.active {
      top: 0;
    }

    .inputWrapper {
      width: 100%;
      background: #fff;
      padding: 18px 20px;

      .inputContainer {
        max-width: 1170px;
        margin: 0 auto;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;

        .searchInput {
          max-width: 768px;
          width: 100%;
          background-color: transparent;
          border: none;
          height: 32px;
          font-size: 18px;
          outline: none;

          @media (max-width: 768px) {
            max-width: 85%;
          }
        }

        .listWrapper {
          position: absolute;
          max-width: 768px;
          width: 100%;
          background: #fff;
          top: calc(100% + 18px);
          left: 50%;
          transform: translate(-50%);
          display: flex;
          flex-direction: column;
          overflow: auto;
          max-height: 300px;

          .searchItem {
            padding: 5px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              width: 70px;
              height: 70px;
              object-fit: cover;
              flex-shrink: 0;
            }

            p {
              color: #000;
              margin: 0;
              font-size: 16px;
              font-weight: 600;
            }

            .searchItemNoImg {
              width: 70px;
              height: 70px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #000;
              font-weight: 700;
              position: relative;
              overflow: hidden;
              flex-shrink: 0;

              &::before {
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                background-image: linear-gradient(
                    217deg,
                    rgba(255, 0, 0, 0.8),
                    rgba(255, 0, 0, 0) 50.71%
                  ),
                  linear-gradient(
                    127deg,
                    rgba(0, 255, 0, 0.8),
                    rgba(0, 255, 0, 0) 70.71%
                  ),
                  linear-gradient(
                    336deg,
                    rgba(0, 0, 255, 0.8),
                    rgba(0, 0, 255, 0) 70.71%
                  );
                background-size: 150vw 150vh;
                animation: 15s infinite granimate;
              }

              @keyframes granimate {
                0%,
                100% {
                  background-position: 0 25%;
                }
                25%,
                75% {
                  background-position: 50% 50%;
                }
                50% {
                  background-position: 100% 100%;
                }
              }
            }
          }
        }
      }
    }
  }
`;
