import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../state/hooks';
import { SearchStyles } from './styles';
import { ApiSearcher } from '../../api/entities/ApiSearcher';
import { AdminApi } from '../../api/adminApi';
import ArrowRight from '../../assets/icons/ArrowRight';

export const Search = () => {
  const params = useLocation();
  const queryParams = new URLSearchParams(params.search);
  const navigate = useNavigate();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const [list, setList] = useState<ApiSearcher[]>([]);
  const [page, setPage] = useState<number>(1);

  const itemCount = useRef<number>(0);

  const handleClickToItem = (item: ApiSearcher) => {
    switch (item.type) {
      case 'blog':
        navigate(`/${interfaceLanguage}/blog/${item.link}`);
        break;
      case 'case':
        if (item.imageURL) {
          navigate(`/${interfaceLanguage}/cases/${item.link}`);
        } else {
          navigate(`/${interfaceLanguage}/${item.link}`);
        }
        break;
      default:
        navigate(`/${interfaceLanguage}/${item.link}`);
        break;
    }
  };

  const goToNextPage = () => {
    if (page < Math.round(itemCount.current / 9)) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    if (queryParams.get('search')) {
      AdminApi.searchPage(interfaceLanguage, queryParams.get('search')!, 9, 9 * (page - 1)).then((res) => {
        if (res.statusCode === 200) {
          itemCount.current = res.count ?? 0;
          if (queryParams.get('search')) {
            setList(res.data);
          } else {
            setList([]);
          }
        }
      });
    }
  }, [page, queryParams.get('search')]);

  return (
    <SearchStyles>
      <div className="container">
        <h1>You searched for: {queryParams.get('search')}</h1>
        <div className="listWrapper">
          {!!list.length && list.map((item) => (
            <div className="listItem" key={item.link} onClick={() => handleClickToItem(item)}>
              {item.imageURL
                ? (
                  <img src={item.imageURL} alt={item.name} />
                )
                : (
                  <div className="listItemNoImg">
                    {item.name}
                  </div>
                )}
              <p>{item.name}</p>
            </div>
          ))}
        </div>
        {itemCount.current > 9 && (
          <div className="paginationWrapper">
            <div className="arrowPrev" onClick={goToPrevPage}><ArrowRight /></div>
            <div className="currentPage">{page} / {Math.round(itemCount.current / 9)}</div>
            <div className="arrowNext" onClick={goToNextPage}><ArrowRight /></div>
          </div>
        )}
      </div>
    </SearchStyles>
  );
};
